import ReactModal from 'react-modal';
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Thumbs } from 'swiper';

import { roomData } from '../../assets/js/Data';

const Room = ({ onClose, dataId }) => {
	// 스와이퍼
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const swiperParams = {
		modules: [EffectFade, Navigation, Thumbs],
		loop: true,
		grabCursor: true,
		navigation: true,
		thumbs: { swiper: thumbsSwiper },
		effect: 'fade',
	};
	const swiperThumbsParams = {
		onSwiper: setThumbsSwiper,
		loop: true,
		slidesPerView: 6,
	};

	// 현재 모달 데이터
	const [curId, setCurId] = useState(dataId);
	const [curData, setCurData] = useState(roomData.find((data) => data.id === dataId));

	// 닫기
	const [close, setClose] = useState(false);
	const handleClickCancel = (e) => {
		setClose(true);
		setTimeout(() => {
			onClose();
			setCurData();
			setCurId();
		}, 500);
	};
	return (
		<ReactModal isOpen className={`Modal.defaultStyles ${close ? 'close' : ''}`}>
			<div className="modal room-modal">
				<div className="modal-title">
					<h2>
						{curData.en}
						<span className="h3">{curData.ko}</span>
					</h2>
				</div>
				<div className="modal-content">
					<div className="figure">
						<Swiper {...swiperParams} className="gallery-images">
							{roomData[curId].images.map((img) => (
								<SwiperSlide key={img}>
									<a className="btn-origin" href={`${process.env.PUBLIC_URL}/img/room/${img}.jpg`} target="_blank" rel="noreferrer" title="새창">
										원본보기
									</a>
									<img src={`${process.env.PUBLIC_URL}/img/room/${img}.jpg`} alt="" />
								</SwiperSlide>
							))}
						</Swiper>
						<Swiper {...swiperThumbsParams} className="gallery-thumbs">
							{roomData[curId].images.map((img) => (
								<SwiperSlide key={img}>
									<img src={`${process.env.PUBLIC_URL}/img/room/${img}.jpg`} alt="" />
								</SwiperSlide>
							))}
						</Swiper>
					</div>
					<div>
						<h3>객실정보</h3>
						<p className="text">{curData.info}</p>
					</div>
					<div>
						<h4>인원</h4>
						<p className="text">{curData.personnel}</p>
					</div>
					<div>
						<h4>객실구성</h4>
						<p className="text">{curData.config}</p>
					</div>
					<div>
						<h4>객실크기</h4>
						<p className="text">{curData.size}</p>
					</div>
				</div>
				{/* <div className="modal-button-area">
					<button onClick={handleClickCancel}>이전글</button>
					<button onClick={handleClickSubmit}>다음글</button>
				</div> */}
				<button className="modal-close" onClick={handleClickCancel}>
					<span className="hide">닫기</span>
				</button>
			</div>
		</ReactModal>
	);
};

export default Room;

import React, { useContext } from 'react';
import { ModalsDispatchContext, ModalsStateContext } from './ModalsContext';

import AccountInfo from '../modalData/AccountInfo';
import Room from '../modalData/Room';
import News from '../modalData/News';
import Facility from '../modalData/Facility';

export const modals = {
	AccountInfo: AccountInfo,
	Room: Room,
	News: News,
	Facility: Facility,
};
const Modals = () => {
	const openedModals = useContext(ModalsStateContext);
	const { close } = useContext(ModalsDispatchContext);

	return openedModals.map((modal, i) => {
		const { Component, props } = modal;
		const { onSubmit, onNext, ...restProps } = props;

		// 닫기
		const onClose = () => {
			close(Component);
		};

		// 제출
		const handleSubmit = () => {
			if (typeof onSubmit === 'function') {
				onSubmit();
			}
			onClose();
		};

		const handleNext = () => {
			onNext();
		};

		return <Component {...restProps} key={i} onClose={onClose} onNext={handleNext} onSubmit={handleSubmit} />;
	});
};

export default Modals;

import ActiveLink from './ActiveLink';

function Gnb({ className, menuClick }) {
	return (
		<nav className={className}>
			<ul onClick={menuClick}>
				<li>
					<ActiveLink path="about" ko="펜션소개" />
				</li>
				<li>
					<ActiveLink path="news" ko="펜션소식" />
				</li>
				<li>
					<ActiveLink path="rooms" ko="객실소개" />
				</li>
				<li>
					<ActiveLink path="reservation" ko="예약하기" />
				</li>
				<li>
					<ActiveLink path="around" ko="주변소개" />
				</li>
				<li>
					<ActiveLink path="faq" ko="자주하는질문" />
				</li>
			</ul>
		</nav>
	);
}

export default Gnb;

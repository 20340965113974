import ReactModal from 'react-modal';
import React, { useState } from 'react';

import { facilityData } from '../../assets/js/Data';

const Room = ({ onClose, dataId }) => {
	// 현재 모달 데이터
	const [curData, setCurData] = useState(facilityData.find((data) => data.id === dataId));

	// 닫기
	const [close, setClose] = useState(false);
	const handleClickCancel = (e) => {
		setClose(true);
		setTimeout(() => {
			onClose();
			setCurData();
		}, 500);
	};
	return (
		<ReactModal isOpen className={`Modal.defaultStyles ${close ? 'close' : ''}`}>
			<div className="modal auto facility-modal">
				<div className="modal-title">
					<h3 className="h2">{curData.name}</h3>
				</div>
				<div className="modal-content">
					<img src={`${process.env.PUBLIC_URL}/img/facility/${curData.count}.jpg`} alt="" />
					<p className="text">{curData.info}</p>
				</div>
				<button className="modal-close" onClick={handleClickCancel}>
					<span className="hide">닫기</span>
				</button>
			</div>
		</ReactModal>
	);
};

export default Room;

import { useEffect, useState, useRef } from 'react';
import { trevelData } from '../assets/js/Data';

function Around() {
	// tourApi
	const key = process.env.REACT_APP_tourAPI;
	const [loading, setLoading] = useState(true);
	const [curContentId, setCurContentId] = useState(127590);
	const [curContent, setCurContent] = useState();
	const changeItem = (contentId) => {
		setCurContentId(contentId);
	};
	const getTourData = async (key, curContentId) => {
		setLoading(true);
		const json = await (
			await fetch(
				`https://apis.data.go.kr/B551011/KorService1/detailCommon1?serviceKey=${key}&MobileOS=ETC&MobileApp=%EB%B4%89%EC%A5%AC%EB%A5%B4%ED%8E%9C%EC%85%98&_type=json&contentId=${curContentId}&defaultYN=Y&firstImageYN=Y&areacodeYN=Y&catcodeYN=Y&addrinfoYN=Y&mapinfoYN=Y&overviewYN=Y`,
			)
		).json();
		setLoading(false);
		setCurContent(json.response.body.items.item[0]);
	};
	useEffect(() => {
		getTourData(key, curContentId);
	}, [key, curContentId]);
	// 2025-05-15 공공데이터 만료
	const trevelType = { 12: '관광지', 14: '문화시설', 15: '축제공연행사', 25: '여행코스', 28: '레포츠', 32: '숙박', 38: '쇼핑', 39: '음식점' };
	// 공공데이터 수정일자 포매팅
	const modifiedTimeFormatting = (str) => {
		return `${str.substr(0, 4)}년 ${str.substr(4, 2)}월 ${str.substr(6, 2)}일`;
	};

	// 아코디언
	const [opened, setOpened] = useState(0);
	const openAccord = (i) => {
		opened === i ? setOpened() : setOpened(i);
	};

	const aroundContent = useRef();

	return (
		<div className="content around">
			<div className="around-tabs">
				<ul className="accord-wrap">
					{trevelData
						.filter((trevel, i, arr) => {
							return arr.findIndex((el) => el.type === trevel.type) === i;
						})
						.map((trevelTypeArr, i) => (
							<li key={i} className={i === opened ? 'accord-panel active' : 'accord-panel'}>
								<button
									className="accord-title"
									onClick={() => {
										openAccord(i);
									}}
								>
									<h3>{trevelTypeArr.type}</h3>
								</button>
								<div className="accord-content">
									{trevelData
										.filter((el) => {
											return el.type === trevelTypeArr.type;
										})
										.map((info) => (
											<button
												key={info.contentId}
												onClick={() => {
													setOpened();
													changeItem(info.contentId);
													setTimeout(() => {
														window.scrollTo({
															top: aroundContent.current.offsetTop - 84,
															behavior: 'smooth',
														});
													}, 500);
												}}
												className={info.contentId === curContentId ? 'active' : ''}
											>
												{info.name}
											</button>
										))}
								</div>
							</li>
						))}
				</ul>
			</div>
			{loading ? (
				<div className="load around-content" ref={aroundContent}>
					주변소개 정보를 불러오는중입니다. 잠시만 기다려주세요
				</div>
			) : (
				<div className="around-content" ref={aroundContent}>
					<h2 className="title">
						{curContent.title}
						<span className="h4">{trevelType[curContent.contenttypeid]}</span>
					</h2>
					<img src={curContent.firstimage} alt="" />
					<div className="table-wrap">
						<table>
							<colgroup>
								<col width="28%" />
								<col />
							</colgroup>
							<tbody>
								<tr>
									<th>주소</th>
									<td>{curContent.addr1 === '' ? '주소 정보가 존재하지 않습니다.' : curContent.addr1}</td>
								</tr>
								{curContent.homepage !== '' &&
									<tr>
										<th>홈페이지</th>
										<td dangerouslySetInnerHTML={{ __html: curContent.homepage }}></td>
									</tr>
								}
								{curContent.tel !== '' &&
									<tr>
										<th>전화번호</th>
										<td>{curContent.tel}</td>
									</tr>
								}
							</tbody>
						</table>
					</div>
					<p className="text" dangerouslySetInnerHTML={{ __html: curContent.overview }}></p>
					<iframe
						title="map"
						src={`https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3254.864167376961!2d${curContent.mapx}!3d${curContent.mapy}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzXCsDIwJzAzLjEiTiAxMjfCsDMxJzU4LjMiRQ!5e0!3m2!1sko!2skr!4v1687135966410!5m2!1sko!2skr`}
						height="400"
						width="100%"
						allowFullScreen=""
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
					></iframe>
					<p className="text">
						이 페이지는 {modifiedTimeFormatting(curContent.modifiedtime)}에 마지막으로 업데이트되었으므로 실제 정보와 다를 수 있습니다. 방문 전 공식 웹사이트에서 자세한 내용을 확인하시기 바랍니다.
					</p>
					<p className="source">데이터 제공 : 한국관광공사</p>
				</div>
			)}
		</div>
	);
}

export default Around;

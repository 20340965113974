import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Gnb from './Gnb';

function Title() {
	// 경로이름 가져오기
	let pathName = useLocation().pathname;
	(function pathFormat() {
		pathName = pathName.substring(pathName.lastIndexOf('/') + 1);
	})();

	// 메뉴
	const [menuToggle, setMenuToggle] = useState(false);
	const menuClick = () => {
		setMenuToggle((prev) => !prev);
	};

	// 날짜 포메팅
	const today = new Date();
	const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

	return (
		<div className="title">
			<div className="pattern">
				<p>
					<span>WELCOME! </span>
					<span className="tm">THANK </span>
					<span className="tm">YOU </span>
					<span className="ta">FOR </span>
					<span>COMING </span>
					<span className="ux">OUR </span>
					<span className="ut">HOME </span>
					<span className="ta">PAGE</span>
					<span>PENSION </span>
					<strong>
						WELCOME<i> TO OUR HOME</i>
					</strong>
				</p>
				<i className="title-pattern"></i>
				<p>
					<span>BONJOUR </span>
					<span className="tm">GET </span>
					<span className="ux">ALL </span>
					<span className="ut">THE </span>
					<span>INFORMATION </span>
					<span className="ta">WHATEVER </span>
					<span className="tm">YOU </span>
					<span>WANT</span>
					<strong>
						<i>PAGE PENSION </i>BONJOUR
					</strong>
				</p>
			</div>
			<div className="title-inner">
				<Link to="/" className="home">
					<i className="logo"></i>
					<div>
						<p>Pension Bonjour</p>
						<h1 className="h4">봉쥬르 펜션</h1>
					</div>
				</Link>
				<p className={`h1 ${pathName === '' ? 'ls5' : ''}`}>{pathName === '' ? `pensionbonjour` : pathName}</p>
				<div className="date">
					<p className="month">{months[today.getMonth()]}</p>
					<p className="year">{today.getFullYear()}</p>
					<button className={menuToggle ? 'btn-menu active' : 'btn-menu'} onClick={menuClick}>
						<p className="hide">메뉴</p>
						<span></span>
					</button>
				</div>
			</div>
			<Gnb className={menuToggle ? 'gnb active' : 'gnb'} menuClick={menuClick} />
		</div>
	);
}

export default Title;

import { useState } from 'react';
import { faqData } from '../assets/js/Data';

function Faq() {
	// 아코디언
	const [opened, setOpened] = useState([]);
	const openAccord = (i) => {
		if (opened.includes(i)) {
			setOpened((prev) => [...prev].filter((el) => el !== i));
		} else {
			setOpened((prev) => [...prev, i]);
		}
	};
	const openAccordAll = () => {
		setOpened(
			Array(faqData.length)
				.fill()
				.map((v, i) => i),
		);
	};
	const closeAccordAll = () => {
		setOpened([]);
	};

	// 탭 sorting
	const [curType, setCurType] = useState('all');
	const typeClick = (e) => {
		setCurType(e.currentTarget.dataset.type);
		setOpened([]);
	};

	return (
		<div className="content faq">
			<div className="sort">
				<h2 className="title">자주하는질문</h2>
				<div className="tab-title">
					<button className={curType === 'all' ? 'active' : ''} data-type="all" onClick={typeClick}>
						<h3>전체</h3>
					</button>
					{[
						{ name: '예약', type: 'reservation' },
						{ name: '이용안내', type: 'use' },
						{ name: '기타', type: 'else' },
					].map((faq, i) => (
						<button key={i} className={curType === faq.type ? 'active' : ''} data-type={faq.type} onClick={typeClick}>
							<h3>{faq.name}</h3>
						</button>
					))}
				</div>
			</div>
			<div className="faq-list">
				<div className="title">
					<h2>{curType}</h2>
					<div className="btns">
						<button onClick={openAccordAll}>모두열기</button>
						<button onClick={closeAccordAll}>모두닫기</button>
					</div>
				</div>
				<ul className="accord-wrap">
					{curType === 'all'
						? faqData.map((q, i) => (
								<li key={i} className={opened.includes(i) ? 'accord-panel active' : 'accord-panel'}>
									<button
										className="accord-title h4"
										onClick={() => {
											openAccord(i);
										}}
									>
										<i>Q. </i>
										<p>{q.question}</p>
									</button>
									<div className="accord-content">
										<div dangerouslySetInnerHTML={{ __html: q.answer }}></div>
									</div>
								</li>
						  ))
						: faqData
								.filter((el) => el.type === curType)
								.map((q, i) => (
									<li key={i} className={opened.includes(i) ? 'accord-panel active' : 'accord-panel'}>
										<button
											className="accord-title h4"
											onClick={() => {
												openAccord(i);
											}}
										>
											<i>Q. </i>
											<p>{q.question}</p>
										</button>
										<div className="accord-content">
											<div dangerouslySetInnerHTML={{ __html: q.answer }}></div>
										</div>
									</li>
								))}
				</ul>
			</div>
		</div>
	);
}

export default Faq;

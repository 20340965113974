import useModals from './hooks/useModals';
import { modals } from './modal/Modals';
import { useEffect } from 'react';

function Footer() {
	const { openModal } = useModals();
	const handleClick = () => {
		openModal(modals.AccountInfo, {});
	};
	// useEffect((null), []);
	return (
		<>
			<footer className="footer">
				<div className="footer-logo">
					<i className="logo"></i>
					<div>
						<p>Pension Bonjour</p>
						<h1 className="h4">봉쥬르 펜션</h1>
					</div>
				</div>
				<div className="footer-info">
					<div className="designed-border">
						<i className="border-top"></i>
						<button className="border-inner" onClick={handleClick}>
							계좌정보
						</button>
						<i className="border-bottom"></i>
					</div>
					<div className="wrap">
						전화번호 : 010-8746-1409
						<br /> 사업자등록번호 : 776-26-00204
						<br /> 이메일 : leylife@naver.com
						<br /> 전라남도 구례군 토지면 섬진강대로 4310-20
					</div>
				</div>
				<a
					className="btn-shortcut h4 pc-cont"
					href="https://map.naver.com/v5/entry/place/1708028749?lng=127.601015&lat=35.2020161&placePath=%2Froom%3Fac=0%26adm_lat=37.5177232%26adm_long=126.934584%26debug=0%26deviceType=pc%26lgl_lat=37.528317%26lgl_long=126.929425%26lgl_rcode=09560110%26ngn_country=KR%26nlu_query=%7B%22pension%22:%7B%22source%22:%22placesearch%22,%22sid%22:%22*%22,%22q%22:%22%EB%B4%89%EC%A5%AC%EB%A5%B4%ED%8E%9C%EC%85%98%22%7D,%22nluQuery%22:%22%EB%B4%89%EC%A5%AC%EB%A5%B4%ED%8E%9C%EC%85%98%22%7D%26nqx_theme=%7B%22theme%22:%7B%22main%22:%7B%22name%22:%22accommodation%22%7D%7D%7D%26r1=%EC%84%9C%EC%9A%B8%ED%8A%B9%EB%B3%84%EC%8B%9C%26r2=%EC%98%81%EB%93%B1%ED%8F%AC%EA%B5%AC%26r3=%EC%97%AC%EC%9D%98%EB%8F%99%26rcode=09560540%26rev=44%26sm=top_hty%26spq=0%26ssc=tab.nx.all%26target=pc%26where=nexearch&entry=plt&c=15,0,0,0,dh"
					target="_blank"
					rel="noreferrer"
					title="새창"
				>
					<span className="h4">네이버 예약</span>
				</a>
				<a
					className="btn-shortcut h4 ta-cont"
					href="https://m.place.naver.com/accommodation/1708028749/room?ac=0&debug=0&ngn_country=KR&nlu_query=%7B%22pension%22%3A%7B%22source%22%3A%22placesearch%22%2C%22sid%22%3A%22*%22%2C%22q%22%3A%22%EB%B4%89%EC%A5%AC%EB%A5%B4%ED%8E%9C%EC%85%98%22%7D%2C%22nluQuery%22%3A%22%EB%B4%89%EC%A5%AC%EB%A5%B4%ED%8E%9C%EC%85%98%22%7D&nqx_theme=%7B%22theme%22%3A%7B%22main%22%3A%7B%22name%22%3A%22accommodation%22%7D%7D%7D&nscs=0&query=%EB%B4%89%EC%A5%AC%EB%A5%B4%ED%8E%9C%EC%85%98&rev=36&sm=mtp_hty.top&spq=0&ssc=tab.m.all&where=m&deviceType=mobile&target=mobile&bizId=1708028749&x=126.9783880&y=37.5666100&entry=plt&businessCategory=pension"
					data-href=""
					target="_blank"
					rel="noreferrer"
					title="새창"
				>
					<span className="h4">네이버 예약</span>
				</a>
			</footer>
			<p className="footer-copy">&copy; 2023 PENSION BONJOUR. All Right Reserved.</p>
		</>
	);
}

export default Footer;

import ReactModal from 'react-modal';
import { useState } from 'react';

const AccountInfo = ({ onSubmit, onClose }) => {
	// const handleClickSubmit = () => {
	// 	onSubmit();
	// };

	const [close, setClose] = useState(false);
	const handleClickCancel = (e) => {
		setClose(true);
		setTimeout(() => {
			onClose();
		}, 500);
	};

	return (
		<ReactModal isOpen className={`Modal.defaultStyles ${close ? 'close' : ''}`}>
			<div className="modal small">
				<div className="modal-title">
					<h3>계좌정보</h3>
				</div>
				<div className="modal-content">
					<table>
						<colgroup>
							<col width="120px" />
						</colgroup>
						<tbody>
							<tr>
								<th>입금은행</th>
								<td>신한은행</td>
							</tr>
							<tr>
								<th>계좌번호</th>
								<td>110035446630</td>
							</tr>
							<tr>
								<th>예금주</th>
								<td>이은영</td>
							</tr>
						</tbody>
					</table>
				</div>
				{/* <div className="modal-button-area">
					<button onClick={handleClickCancel}>확인</button>
					<button onClick={handleClickSubmit} className="sub">확인</button>
				</div> */}
				<button className="modal-close" onClick={handleClickCancel}>
					<span className="hide">닫기</span>
				</button>
			</div>
		</ReactModal>
	);
};

export default AccountInfo;

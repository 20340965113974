// 뉴스 시작
const newsData = [
	{
		id: 30,
		caption: '숲 오솔길따라 느껴지는 가을향기',
		title: '지리산 피아골의 가을이 깊어갑니다.',
		date: '2024.11.01',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/300.jpg" alt="" /><p>계절도 마음도 가다려주지 않으니 머물고 싶은 순간이라면 그저 진심을 다 하라 합니다.</p><img src="${process.env.PUBLIC_URL}/img/news/301.jpg" alt="" /><p>단풍잎 처럼 세월에 물들고</p><img src="${process.env.PUBLIC_URL}/img/news/302.jpg" alt="" /><p>홍시처럼 시간에 익어가며</p><img src="${process.env.PUBLIC_URL}/img/news/303.jpg" alt="" /><p>노을처럼 아름답게 저물기를...</p><img src="${process.env.PUBLIC_URL}/img/news/304.jpg" alt="" /><p class="text">곱고 아름다운 계절 가을에 천천히 머무르는 동안 깨끗하고 편안한 휴식이도록 정성을 더하겠습니다. 감사합니다.</p>`,
	},
	{
		id: 29,
		caption: '제48회 지리산피아골 단풍축제',
		title: '지리산 피아골 단풍축제 소식 알립니다.',
		date: '2024.10.23',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/290.jpg" alt="" /><p>뿌리내릴곳에 뿌리 내린 나무는 아름답다고 합니다.</p><img src="${process.env.PUBLIC_URL}/img/news/291.jpg" alt="" /><p>놓일 곳에 놓인 바위 또한 하나의 아름다움입니다.</p><img src="${process.env.PUBLIC_URL}/img/news/292.jpg" alt="" /><p>꽃 필 때 를 알아 피운 꽃이 아름답고</p><img src="${process.env.PUBLIC_URL}/img/news/293.jpg" alt="" /><p>단풍 일 때를 알아  물든 잎도 참 아름다움 입니다.</p><img src="${process.env.PUBLIC_URL}/img/news/294.jpg" alt="" /><p>쓰일 곳에 쓰인 인간의 말 또한 아름다움 이라..</p><p class="text">깊어가는 가을 선선한 하늘 바람  향긋한 숲 향기 함께 나누는 건강한 가을로 찾아 든 단풍의 울긋불긋한 빛들이 아름다움 가득 더하는 지리산 피아골 계곡 길로 천천히 천천히 걸으며 가을의 빛 듬뿍담아 누리시길</p><p class="text">깊어가는 까만 가을 밤 맑은 별빛 머무르는 펜션 봉쥬르에서 깨끗하고 편안한 휴식이도록 정성을 더하겠습니다. 감사합니다.</p>`,
	},
	{
		id: 28,
		caption: '따사로운 햇볕에 따라 말리는 고추',
		title: '가을 바람이 참 좋은 시월의 멋진 날입니다.',
		date: '2024.10.07',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/280.jpg" alt="" /><p>사소한 것에서 오는 당연한 것들이 얼마나 큰 감사이고 행복인지.. </p><img src="${process.env.PUBLIC_URL}/img/news/281.jpg" alt="" /><p>선선한 바람으로 흔들리는 갈잎이, </p><img src="${process.env.PUBLIC_URL}/img/news/282.jpg" alt="" /><p>홍청으로 물든 단풍 은행이,</p><img src="${process.env.PUBLIC_URL}/img/news/283.jpg" alt="" /><p>가을저녁 서산으로 넘어가는 태양빛이,</p><img src="${process.env.PUBLIC_URL}/img/news/284.jpg" alt="" /><p>너른들판 황금빛 벼이삭이, </p><p class="text">아무렇지도 않게 존재하는 모든 것들이 감사이고 좋은 날들입니다. </p><p class="text">가을바람이 참 좋은 시월의 멋진 날 천천히 걸으며 담은 호흡 한 숨에 온 몸으로 그 시원함 가득하시길.. 가을하늘이 참 맑은 시월의 멋진 날 편안히 머무르며 바라본 눈길 닿은 곳에 온 마음으로 그 행복이 가득이시길.. </p><p class="text">깊어가는 까만 가을 밤 맑은 별빛 머무르는 펜션 봉쥬르에서 깨끗하고 편안한 휴식이도록 정성을 더하겠습니다. 감사합니다.</p>`,
	},
	{
		id: 27,
		caption: '높은 가을하늘과 살랑이는 가을바람',
		title: '한낮의 햇살 사이사이로 기분 좋은 가을바람이 불어옵니다.',
		date: '2024.09.07',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/270.jpg" alt="" /><p>느림"은 부드럽고 우아합니다.</p><img src="${process.env.PUBLIC_URL}/img/news/271.jpg" alt="" /><p>배려깊은 삶의 방식입니다.</p><img src="${process.env.PUBLIC_URL}/img/news/272.jpg" alt="" /><p>한가로이 거닐기</p><img src="${process.env.PUBLIC_URL}/img/news/273.jpg" alt="" /><p>누군가의 이야기를 몰입해서 듣기..</p><img src="${process.env.PUBLIC_URL}/img/news/274.jpg" alt="" /><p>꿈꾸기 .. 기다리기.. 한낮의 나른함.. 같은<br>느림"의 미학..</p><p class="text">시원한 계곡물소리로 즐거웠던 여름이, 고요히 불어드는 바람, 푸른 가을 하늘 사이로 날립니다. 계절이 오가는 공기 속 흐름이 때로는 설레임으로 약간은 아쉬움으로 엇갈리는 시간..</p><p class="text">늘 한가위만 같아라..던 풍요로운 계절, 가을의 초입 9월 한가로이 거닐며, 소중한 사람들의 마음을 나누고 다시, 꿈을 가다듬고 그렇게 찬찬히 다가오는 좋은 계절 가을을 기다려보는건 어떨까요..</p><p class="text">까만 밤 별빛 머무르는 펜션 봉쥬르에서 깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다. 감사합니다.</p>`,
	},
	{
		id: 26,
		caption: '시원하게 여름을 보내는 피서객',
		title: '피아골계곡에서 시원하고 건강한 8월의 여름휴가 보내세요.',
		date: '2024.08.02',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/260.jpg" alt="" /><p>아름다운 것을<br>아름답다고 느낄 때 우린 행복합니다.</p><img src="${process.env.PUBLIC_URL}/img/news/261.jpg" alt="" /><p>고마운 것을<br>감사하다고 전할 때 마음이 따뜻해집니다.</p><img src="${process.env.PUBLIC_URL}/img/news/262.jpg" alt="" /><p>자연이 나누어주는  소박한 선물이<br>누구나  누릴 수 있는 즐거움으로 </p><img src="${process.env.PUBLIC_URL}/img/news/263.jpg" alt="" /><p>보듬고 보살피는 정성스런 손길로<br>누구든  감사히 즐길 수 있는  자연으로</p><p>바라만 봐도 시원한 물소리에<br>마음 한껏 맑아지시길<br>풍덩풍덩 즐기는 물놀이에 <br>몸도 따라 기꺼이 밝아지시길</p>`,
	},
	{
		id: 25,
		caption: '보기만해도 시원한 봉쥬르 펜션 앞 계곡',
		title: '장대비 내리고 맑아진 7월의 시작이 제대로 여름입니다.',
		date: '2024.07.05',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/250.jpg" alt="" /><p>장대비가 먹구름 몰더니<br>다시 떠오른 태양이<br>맑은 바람으로<br>푸른 하늘 흰구름을 품고<br>뜨거운 여름입니다.</p><img src="${process.env.PUBLIC_URL}/img/news/251.jpg" alt="" /><p>계곡은 개골개골 끊임없이 울어대고<br>여름 햇살 바람 하늘 구름은 그지없이 맑고 </p><img src="${process.env.PUBLIC_URL}/img/news/252.jpg" alt="" /><p>언제 다시 비비람 불고 장마비가 내릴지라도,</p><img src="${process.env.PUBLIC_URL}/img/news/253.jpg" alt="" /><p>다만, 오늘은<br>쨍 하기만 한 여름입니다.<br>비가 내리면 어떨까,<br>하늘이 푸르면 어떨까<br>바람이 뜨거우면<br>갑자기 먹구름이 몰아치면<br>나무그늘이 시원하면<br>계곡물이 맑으면 어떨까..<br>모든게 어떠면..무어겠습니까..<br>여름은 그냥 여름인걸요.<br>저어기 자연은 그대로 귀한 감사인걸요. </p><img src="${process.env.PUBLIC_URL}/img/news/254.jpg" alt="" /><p>삶은..여전히 즐기는 자의 몫 입니다.<br>뜨거운 여름 시원한 계곡으로<br>여름날의 작은 추억 한껏 담는<br>즐거운 휴가여행길<br>깨끗하고 편안한 휴식으로<br>정성을 더하겠습니다.<br>감사합니다.</p>`,
	},
	{
		id: 24,
		caption: '산봉우리 너머로 떠오르는 태양',
		title: '계곡 물 소리가 즐거워지는 여름의 초입 6월입니다.',
		date: '2024.06.05',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/240.jpg" alt="" /><p class="text">아침마다 떠오르는 태양이</p><img src="${process.env.PUBLIC_URL}/img/news/241.jpg" alt="" /><p class="text">까만 밤으로 피어오른 달빛이,</p><img src="${process.env.PUBLIC_URL}/img/news/242.jpg" alt="" /><p class="text">우리를 숨 쉬게 하는 맑은 공기들이,</p><img src="${process.env.PUBLIC_URL}/img/news/243.jpg" alt="" /><p class="text">그 깊은 산에서 솟아나는 샘물이</p><img src="${process.env.PUBLIC_URL}/img/news/244.jpg" alt="" /><p class="text">맑은 계곡물 아래로 숨어든 파란 하늘이.</p><p>소리 없는<br>이 모든 선한 것들이..<br>우리를 살게 만듭니다.</p><p>아낌없이 내어주는<br>자연의 고요한 소리에 귀 기울이며<br>여름의 계곡으로 떠나볼까요.</p><p class="text">소중한 사람들과 천천히 머무르며 즐기고 휴식 하며 나누는 시간이 살며 살아가는 힘이 되는 편안한 여름 여행 이도록 정성을 더하겠습니다. 감사합니다.</p>`,
	},
	{
		id: 23,
		caption: '푸른 봄이 절정인 구례 서시천 변 자전거길',
		title: '지리산 섬진강 변에, 구례 서시천 변에, 연두빛 푸른 봄이 절정입니다.',
		date: '2024.05.03',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/230.jpg" alt="" /><p class="text">천천히 또박또박 두 발로 걸어</p><img src="${process.env.PUBLIC_URL}/img/news/231.jpg" alt="" /><p class="text">수양버들 춤추는 그 길 따라</p><img src="${process.env.PUBLIC_URL}/img/news/232.jpg" alt="" /><p class="text">맑은 물 일렁이는 저 강 따라</p><img src="${process.env.PUBLIC_URL}/img/news/233.jpg" alt="" /><p class="text">흰 구름 풀어놓은 파란 하늘에<br>연두빛 푸른 봄이 절정 입니다.</p><img src="${process.env.PUBLIC_URL}/img/news/234.jpg" alt="" /><p class="text">삶은..<br>쉼없이 달려 이겨내야 할 경주가 아니라<br>함께 나누며 걸어가야 할 여행길 이어야 한다고..</p><p class="text">좋은 봄 5월에 머물러<br>자연이 나누어주는 감사함으로<br>건강한 시간 나누는<br>소중한 추억 되시길...</p>`,
	},
	{
		id: 22,
		caption: '이쁘게 피어난 지리산 금낭화',
		title: '지리산 금낭화가 봄바람에 하늘거립니다.',
		date: '2024.04.19',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/220.jpg" alt="" /><p class="text">4월은 분주하고 계절은 바삐 흘러갑니다.</p><img src="${process.env.PUBLIC_URL}/img/news/221.jpg" alt="" /><p class="text">꽃이 피는 시기가, 잎이 자라는 시기가 다르듯이</p><img src="${process.env.PUBLIC_URL}/img/news/222.jpg" alt="" /><p class="text">사람마다 시기가 다르다고..<br>그러기에 인생은 끝까지  가보아야 알 수 있다고 합니다.</p><p class="text">꽃 핀 봄을 즐기며,<br>연초록으로 자라나는 새싹들을 안으며<br>천천히 가보아야겠습니다.<br>내 계절이 봄 이었을까,<br>여름이었을까..<br>아님 아직 오지않은 갈색낙엽의 가을,<br>흰백색의 순수 겨울 일지도 모를 일 이니까요..</p><img src="${process.env.PUBLIC_URL}/img/news/223.jpg" alt="" /><p class="text">지리산 자락 숲 길 따라,</p><img src="${process.env.PUBLIC_URL}/img/news/224.jpg" alt="" /><p class="text">섬진강 변 뚝 길 따라,</p><p class="text">좋은사람들과 건강한 걸음으로<br>행복한 시간 나누는 햇살 좋은 봄,<br>살랑이는 기분좋은 바람 마주해 보는 건 어떨까요..</p><p class="text">머무는 소중한 시간들에<br>깨끗하고 편안한 휴식이도록<br>정성을 더하겠습니다.<br>감사합니다.</p>`,
	},
	{
		id: 21,
		caption: '벚꽃이 흐드러지게 피어난 봉쥬르 펜션',
		title: '구례 300리, 하동 십리 벚꽃 길이 하얗게 열리기 시작합니다.',
		date: '2024.03.27',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/210.jpg" alt="" /><p class="text">아침 햇살 반짝이는 생명의 봄 입니다.<br>봄 꽃 시샘 바람이<br>겨울의 아쉬움 담고 멀어져 간 시간..</p><img src="${process.env.PUBLIC_URL}/img/news/211.jpg" alt="" /><p class="text">파릇파릇 새싹이 돋아나고<br>노랑 하양 예쁜 꽃망울이 피어나고 </p><img src="${process.env.PUBLIC_URL}/img/news/212.jpg" alt="" /><p class="text">하여... 진심, 봄 마중 나서야겠습니다.</p><img src="${process.env.PUBLIC_URL}/img/news/213.jpg" alt="" /><p class="text">지리산 자락 어느 곳을 걸어도,<br>섬진강 변 어디에 머물러도 <br>따사로운 봄 향기 가득한 고마운 시간..</p><img src="${process.env.PUBLIC_URL}/img/news/214.jpg" alt="" /><p class="text">산봄 꽃 햇살 바람 구름 하늘..따라<br>봄 기운 가득한 여행길<br>천천히 오시는 걸음이<br>깨끗하고 편안할 수 있도록<br>정성을 더하겠습니다. 감사합니다.</p>`,
	},
	{
		id: 20,
		caption: '구례 산수유 꽃 축제 안내',
		title: '저만치서 천천히 오는 남도의 봄, 꽃 소식전합니다.',
		date: '2024.02.13',
		html: `<p>괜찮지 않아도 괜찮습니다.</p><p>괜찮지 않은 날 괜찮으려 애쓰기 보다</p><p>휴식을 더 많이 취하고, 명상하고, 운동하며</p><p>소중한 스스로를 돌보는데 시간을 쓰세요.</p><p>그렇게 담담히 지내다 보면</p><p>괜찮은 날도 옵니다.</p><p>겨울 가면 봄이 오듯이요..</p><p>(정민. 나는 있는 그대로 충분하다.)</p><p class="text">지리산아래 까만 겨울을 지내다 그 아득한 어둠의 맑고 청아한 추운겨울도 괜찮았습니다만,<br>역시 봄 인가봅니다. 정말 괜찮은건말입니다.</p><img src="${process.env.PUBLIC_URL}/img/news/200.jpg" alt="" /><p class="text">지난한 겨울 추위 견뎌내고 기어이 희망이라는 봄 앞에 인내로 움트는 꽃망울들, 따뜻한 봄이어서 감사한 날들에 함께하는 예쁜 봄 꽃 축제소식 전합니다.</p><img src="${process.env.PUBLIC_URL}/img/news/201.jpg" alt="" /><p class="text">얼마이지 않을 겨울 건강히 보내시고 조금씩 천천히 다가오는 남도의 봄 맞이 여행길에 깨끗하고 편안한 휴식 이도록 정성을 더하겠습니다. 감사합니다</p>`,
	},
	{
		id: 19,
		caption: '천은사 상생의 소나무 숲길',
		title: '2024 갑진년 새해가 밝았습니다.',
		date: '2024.01.27',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/190.jpg" alt="" /><p class="text">새해맞이 천은사 상생의 소나무 숲길 산책에</p><img src="${process.env.PUBLIC_URL}/img/news/191.jpg" alt="" /><p class="text">높푸른 소나무 펼쳐진 잔잔한 호수 위 나무 의자에</p><img src="${process.env.PUBLIC_URL}/img/news/192.jpg" alt="" /><p class="text">천은제 유유히 흐르며 다정한 한 쌍의 물새 몸짓에</p><img src="${process.env.PUBLIC_URL}/img/news/193.jpg" alt="" /><p class="text">산이 하늘이 구름이 그대로 호수 인 곳,</p><img src="${process.env.PUBLIC_URL}/img/news/194.jpg" alt="" /><p class="text">행복은 단지 방향을 결정하는 것이지 영혼의 상태가 아니라고 합니다.</p><p class="text">모두가 빠를 수 없고, 모두의 행복이 같을 수 없다는 당연한 진리에 온전히 자신만의 속도로 자기만의 행복을 그려나가는 건강하고 값진 새해이길 소망합니다.</p><p class="text">새해맞이 여행길 귀한 걸음 그 발걸음이 머무는 곳에 편안하고 깨끗한 휴식이도록 정성을 더하겠습니다. 감사합니다.</p>`,
	},
	{
		id: 18,
		caption: '오산 사성암 전망대에서 바라보는 해돋이',
		title: '2023 마무리 겨울여행 할인이벤트 안내드립니다.',
		date: '2023.12.04',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/180.jpg" alt="" /><p class="text">오산 사성암 전망대 해돋이</p><img src="${process.env.PUBLIC_URL}/img/news/181.jpg" alt="" /><p class="text">지리산 노고단 설경</p><img src="${process.env.PUBLIC_URL}/img/news/182.jpg" alt="" /><p class="text">지리산 천은사 상생의 소나무숲 길</p><img src="${process.env.PUBLIC_URL}/img/news/183.jpg" alt="" /><p class="text">구례 화엄사 각황전</p><img src="${process.env.PUBLIC_URL}/img/news/184.jpg" alt="" /><p class="text">구례 섬진강 대나무숲길</p><p class="text">어디라도 좋을 넉넉한 어머니의 품 지리산 자락에도 하얀빛 서리로 내리는 시린 겨울이 왔습니다.</p><p class="text">마른 낙엽들이 바스락거리고 마음껏 차가와져 한없이 맑아진 시간 한해의 마무리 시간들 함께 하는 겨울 여행길에 깨끗하고 편안한 휴식이도록 정성을 더하겠습니다.</p><p class="text">네이버 예약시 10% 할인쿠폰 드립니다. 문의사항은 010-8746-1409 로 전화주세요. 자세하게 안내해드리겠습니다. 감사합니다.</p>
		`,
	},
	{
		id: 17,
		caption: '제47회 지리산 피아골 단풍축제',
		title: '2023 지리산 피아골 단풍 축제 소식입니다.',
		date: '2023.10.23',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/170.jpg" alt="" /><p>일시 : 2023. 11. 4 (토) ~ 5 (일)<br>장소 : 연곡사, 피아골 계곡 일원<br>주제 : 산도 물도 그대도 단풍이어라.</p><img src="${process.env.PUBLIC_URL}/img/news/171.jpg" alt="" /><p class="text">깊어가는 가을의 건강한 여행길</p><img src="${process.env.PUBLIC_URL}/img/news/172.jpg" alt="" /><p class="text">머무시는 동안 </p><img src="${process.env.PUBLIC_URL}/img/news/173.jpg" alt="" /><p class="text">깨끗하고 편안한 휴식 이도록 정성을 더하겠습니다.</p><img src="${process.env.PUBLIC_URL}/img/news/174.jpg" alt="" /><p class="text">전화예약시 할인 혜택드리며 네이버 예약시 연박 할인 됩니다. 문의사항은 010 - 8746 - 1409 로 전화주세요. 감사합니다.</p><p class="text">#지리산피아골 #피아골단풍 #피아골단풍축제 #지리산봉쥬르펜션 #피아골봉쥬르펜션</p>`,
	},
	{
		id: 16,
		caption: '맑은 가을하늘',
		title: '시월의.. 멋진 날들입니다.',
		date: '2023.10.23',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/160.jpg" alt="" /><p class="text">눈을 뜨기 힘든  가을 보다 높은,  저 하늘이 기분 좋아</p><img src="${process.env.PUBLIC_URL}/img/news/161.jpg" alt="" /><p class="text">창 밖에 앉은 바람 한 점에도 사랑은 가득한 걸</p><img src="${process.env.PUBLIC_URL}/img/news/162.jpg" alt="" /><p class="text">널 만난 세상 더는 소원 없어 바램은 죄가 될 테니까</p><img src="${process.env.PUBLIC_URL}/img/news/163.jpg" alt="" /><p class="text">살아가는 이유,  꿈을 꾸는 이유, 모두가 너 라는 걸</p><img src="${process.env.PUBLIC_URL}/img/news/164.jpg" alt="" /><p class="text">네가 있는 세상 살아가는 동안 더 좋은 것은 없을거야 시월의 어느 멋진 날에~</p><p class="text">참 좋은 시월의 멋진 날들입니다. 가을, 하늘,  바람, 소원 , 꿈..그리고 삶.. 시월의 멋진 날들을 만나는 동안 더 풍성하고 아름다운 가을을 마음에 몸에 담아 날마다 건강하시길 그렇게 오래도록  따뜻하시길~</p><p class="text">소중한 사람들과 동행하는 귀한 여행길이 편안하고 깨끗할 수 있도록 정성을 더하겠습니다. 문의 사항은 010-8746-1409 로 전화 주세요. 자세하게 안내해드리겠습니다. 감사합니다.</p><p class="text">#가을단풍 #가을산행 #가을여행 #지리산봉쥬르펜션 #피아골봉쥬르펜션</p>`,
	},
	{
		id: 15,
		caption: '선선한 바람이 부는 피아골 계곡',
		title: '파란 하늘 하얀 구름 맑은 계곡 시원한 바람 있어 참 좋은 가을 입니다.',
		date: '2023.09.24',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/150.jpg" alt="" /><p class="text">여전히 남아도는 여름의 열정은 며칠 남지 않은 9월의 한낮을 따사롭게 비추며시원한 계곡물 아래 아쉬움으로 머무르고, 아침 저녁 불어오는 선선한 바람이 가을맞이 채비를 서두릅니다.</p><img src="${process.env.PUBLIC_URL}/img/news/151.jpg" alt="" /><p class="text">구름을 그린 푸른 빛 하늘이 높아지고 햇살을 안은 물 빛 계곡이 깊어지는 시간 맑은 바람이 뜨거운 태양을 달래며 즐거웠던 여름 사이사이로 시원한 가을이 옵니다.</p><img src="${process.env.PUBLIC_URL}/img/news/152.jpg" alt="" /><p class="text">지리산 자락 어디라도 오르면 만날 하늘과 구름, </p><img src="${process.env.PUBLIC_URL}/img/news/153.jpg" alt="" /><p class="text">섬진강 변 어느 곳이라도 걸으면 마주할 풍경이</p><img src="${process.env.PUBLIC_URL}/img/news/154.jpg" alt="" /><p class="text">몸과 마음을 가을로 물들이며 잔잔해지는 여행길</p><p class="text">소중한 사람들과 함께 가을 밤 별빛 머무르는 펜션 봉쥬르 에서 깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다.</p><p class="text">홈페이지 참고하시어 전화 문의 예약 시 할인 혜택 드리며 네이버 예약 시 연박 할인됩니다. 문의 사항은 010-8746-1409 로 전화 주세요. 자세하게 안내해드리겠습니다. 감사합니다.</p>`,
	},
	{
		id: 14,
		caption: '장대처럼 쏟아지는 장맛비',
		title: '장맛비 그치고 맑아진 하늘과 계곡이 푸릅니다.',
		date: '2023.07.20',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/140.jpg" alt="" /><p class="text">세차게 내리는 여름 장맛비가 장대처럼 쏟아집니다. 요란한 비 소리, 계곡 물 소리도 그 가운데 마음을 놓으니 그저 고요한 것을</p><img src="${process.env.PUBLIC_URL}/img/news/141.jpg" alt="" /><p class="text">앞 산 나무 뿌리마저 흠뻑 적시고 물안개로 피어납니다.</p><img src="${process.env.PUBLIC_URL}/img/news/142.jpg" alt="" /><p class="text">다시 맑아진 아침 , 하얀 뭉게구름 파란 하늘 안에서 눈 부십니다.</p><img src="${process.env.PUBLIC_URL}/img/news/143.jpg" alt="" /><p class="text">그렇게 놓아둔 고마운 자연 있어, 흐르는 물 소리가 노래가 되어 삶의 위로를 얻습니다.</p><p class="text">사랑이 없는 인생이란 여름이 없는 계절과 같다..는 스웨덴 속담이 계곡물 소리로 담기는 여름  그 한가운데 천천히 머무르며 즐겁고 시원하게, 또 때론 고요하고 잔잔하게, 다녀가시는 여름 여행길에 동행하며 편안하고 깨끗한 휴식 이도록 정성을 더하겠습니다.</p><p class="text">여름맞이 예약진행중입니다. 홈페이지 참고하시어 전화문의 예약시 할인혜택드리며, 네이버예약시 연박할인됩니다. 깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다. 문의사항은 010-8746-1409 로전화주세요. 자세하게 안내해드리겠습니다. 감사합니다.</p>`,
	},
	{
		id: 13,
		caption: '맑디맑은 푸른빛 펼쳐진 펜션 앞 풍경',
		title: '계곡물 소리가 즐거운 여름입니다.',
		date: '2023.06.15',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/130.jpg" alt="" /><p class="text">연두빛 잎들이 초록초록 거리며 짙어져가는 여름</p><img src="${process.env.PUBLIC_URL}/img/news/131.jpg" alt="" /><p class="text">맑디맑은푸른빛 은은히 펼쳐진 하늘에</p><img src="${process.env.PUBLIC_URL}/img/news/132.jpg" alt="" /><p class="text">몽글몽글 피어난 하얀 구름 가득 입니다.</p><img src="${process.env.PUBLIC_URL}/img/news/133.jpg" alt="" /><p class="text">맑은 계곡 속으로 여름빛이 내려 노랗게 반짝거리는 시간들</p><img src="${process.env.PUBLIC_URL}/img/news/134.jpg" alt="" /><p class="text">지리산 피아골 맑은 계곡 앞 봉쥬르에서 잔잔하게 불어오는 섬진강의 시원한 바람은 덤으로 소중한 사람들과 함께나누는 한 여름의 즐겁고 행복한 시간 되시길 바라며</p><p class="text">여름맞이 예약진행중입니다. 홈페이지 참고하시어 전화문의 예약시 할인혜택드리며, 네이버예약시 연박할인됩니다. 깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다. 문의사항은 010-8746-1409 로전화주세요. 자세하게 안내해드리겠습니다. 감사합니다.</p>`,
	},
	{
		id: 12,
		caption: '봄 벚꽃 사이로 비치는 하늘',
		title: '따뜻한 햇살 포근한 바람, 걷기 좋은 계절 지리산의 봄 입니다.',
		date: '2023.04.04',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/120.jpg" alt="" /><p class="text">지천으로 흐트러지던 하얀 꽃송이가</p><img src="${process.env.PUBLIC_URL}/img/news/121.jpg" alt="" /><p class="text">맑은 계곡물 위로 살포시 내립니다. 바라보던 눈 속에도 꽃송이 앉아 마음 한가득 봄을 채웁니다.</p><img src="${process.env.PUBLIC_URL}/img/news/122.jpg" alt="" /><p class="text">꽃이 지나간 자리에 초록이 눈부시고 온천지가 살아있다는 소식으로 생명의 물감을 마구 풀어내고 있다 는 오두막 편지 속 법정스님의 말씀이 고스란히 담깁니다.</p><img src="${process.env.PUBLIC_URL}/img/news/123.jpg" alt="" /><img src="${process.env.PUBLIC_URL}/img/news/124.jpg" alt="" /><p class="text">따뜻한 햇살 포근한 바람, 걷기 좋은 계절, 지리산 자락 어디라도 좋을 봄 가까이, 봄 맞이 지역축제 2023 하동 세계차 엑스포 2023 순천만 국제 정원 박람회 소식 전합니다. 천천히 다녀가시며 걷는 걸음 걸음이 함께하는 건강한 봄 날 이길 소망 하며 고요히 머물러 휴식 할 수 있는 깨끗하고 편안한 공간이도록 정성을 더하겠습니다.</p><p class="text">문의사항은 010-8746-1409 로 전화주세요. 자세하게 안내해드리겠습니다. 감사합니다.</p>`,
	},
	{
		id: 11,
		caption: '벚꽃이 만개한 구례의 300리 벚꽃 축제',
		title: '하얀꽃송이 가득 내리는 벚꽃길 소식 전합니다.',
		date: '2023.03.22',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/110.jpg" alt="" /><p class="text">"엊그제 겨울 지나 새봄이 돌아오니, 복숭아꽃과 살구꽃은 저녁 햇빛 속에 피어있고 푸른 나무와 꽃다운 풀은 가랑비 속에 푸르도다." 조선시대의 문인 정극인의 '상춘곡'이 절로 생각나는 봄</p><img src="${process.env.PUBLIC_URL}/img/news/111.jpg" alt="" /><p class="text">앞산 위로 아침 햇살이 반짝이더니 하얀꽃송이 몽글몽글 소복히 피어오릅니다.</p><img src="${process.env.PUBLIC_URL}/img/news/112.jpg" alt="" /><p class="text">맑은 계곡물 아래로  담긴 아침햇살에 물빛도 따라 반짝이고,</p><img src="${process.env.PUBLIC_URL}/img/news/113.jpg" alt="" /><p class="text">이제 막 피어나는 벚꽃잎을 내려보는 하늘은 그  하얀빛에 반해 더 푸르기를..</p><img src="${process.env.PUBLIC_URL}/img/news/114.jpg" alt="" /><p class="text">하이얀 꽃 따라 오시는 길, 따사로운 햇살 맑은 바람 함께 지나는 즐거운 봄 여행이시길 바라며 </p><p class="text">함께하는 소중한 시간들에 머물러 깨끗하고 편안한 휴식이도록 정성을 더하겠습니다. 문의사항은 010-8746-1409로 전화주세요. 친절하게 안내해 드리겠습니다. 감사합니다.</p>`,
	},
	{
		id: 10,
		caption: '봄소식 가득 담은 산수유 꽃축제',
		title: '조금씩 천천히 다가오는 남도의 3월 봄꽃 축제 안내드립니다.',
		date: '2023.02.09',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/100.jpg" alt="" /><p class="text">2월의 겨울이기엔 마당 한가득 비추는 햇살도 해를 품고 도는 바람도 왠지 봄을 흉내 낸 것 같아 실내로 두었던 분을 들어 너른 마당으로 내어주고 물 샤워로 시원하게 흠뻑 적시어 겨우내 헛헛하던 목 마름 달래 줍니다.</p><img src="${process.env.PUBLIC_URL}/img/news/101.jpg" alt="" /><p class="text">여전히 남아있는 겨울도 꽃샘으로 여운 남기며 물러날 시간들이 머지않은 남도의 2월, 조금씩 천천히 다가오는 3월의 노오란 봄 소식 전해봅니다.</p><img src="${process.env.PUBLIC_URL}/img/news/102.jpg" alt="" /><p class="text">따뜻한 봄 마중 나선 길에 마주할 예쁜 꽃들로 겨우내 움추렸던 몸과 마음들이 활짝 피어나는 즐거운 여행길 되시길 바라며</p><img src="${process.env.PUBLIC_URL}/img/news/103.jpg" alt="" /><p class="text">함께하는 소중한 시간들에 머물러 깨끗하고 편안한 휴식이도록 정성을 더하겠습니다.</p><img src="${process.env.PUBLIC_URL}/img/news/104.jpg" alt="" /><p class="text">문의사항은 010-8746-1409로 전화주세요. 친절하게 안내해 드리겠습니다. 감사합니다.</p>`,
	},
	{
		id: 9,
		caption: '지리산에서 보는 일출',
		title: '계묘년 새해가 밝았습니다.',
		date: '2023.01.18',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/90.jpg" alt="" /><img src="${process.env.PUBLIC_URL}/img/news/91.jpg" alt="" /><img src="${process.env.PUBLIC_URL}/img/news/92.jpg" alt="" /><img src="${process.env.PUBLIC_URL}/img/news/93.jpg" alt="" /><p class="text">좋은 사람들과의 여행길에 천천히 머무르며 소중하게 나누는 귀한 시간들로 맞이하는 따뜻한 새해이시길 바라며 깨끗하고 편안할 수 있도록 정성을 더하겠습니다. 문의사항은 010-8746-1409 로 전화주세요. 친절하게 안내해드리겠습니다. 감사합니다.</p>`,
	},
	{
		id: 8,
		caption: '피아골 계곡에 비치는 봉쥬르펜션 야경',
		title: '겨울여행 시즌 이벤트 할인 행사 안내드립니다.',
		date: '2022.12.03',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/80.jpg" alt="" /><p class="text">따뜻한 봄 하얀 꽃망울로, 뜨거운 여름 시원한 계곡물로, 선선한 가을 화려한 단풍으로 시간을 달래주고 품어주던 이 한해가 마지막 남은 계절 겨울을 데려옵니다.</p><img src="${process.env.PUBLIC_URL}/img/news/81.jpg" alt="" /><p class="text">지리산 피아골 맑은 계곡물로 시리도록 투명해지는 마음 있어 그 자연이 고맙고 잔잔히 피어오른 섬진강 물빛으로 일렁이는 삶의 여운이 느껴져 그 자연이 경이로와지는 시간</p><img src="${process.env.PUBLIC_URL}/img/news/82.jpg" alt="" /><p class="text">잊어버렸을까 싶던 처음을 반추하고 차근차근 남은 시간들을 정리하며 소중한 인연 함께하는 겨울 여행 길에 따뜻하고 편안한 휴식 일 수 있도록 정성을 더하겠습니다.</p><p class="text">겨울여행 시즌 이벤트 할인 행사 안내드립니다. 문의사항은 010-8746-1409 로 전화주세요. 친절하게 안내해드리겠습니다. 감사합니다.</p>
		`,
	},
	{
		id: 7,
		caption: '가을 가득한 피아골 계곡',
		title: '단풍진 피아골 계곡',
		date: '2022.10.27',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/70.jpg" alt="" /><p class="text">푸른 하늘, 맑은 계곡 사이로 가을이 깊어집니다.</p><img src="${process.env.PUBLIC_URL}/img/news/71.jpg" alt="" /><p class="text">홍단풍 청단풍 어우러져 가을을 물들이고</p><img src="${process.env.PUBLIC_URL}/img/news/72.jpg" alt="" /><p class="text">파아란 하늘로 곧게 뻗은 노오란 은행 나무로 가을 빛 더해집니다.</p><img src="${process.env.PUBLIC_URL}/img/news/73.jpg" alt="" /><p class="text">피아골 계곡 걸으며 따라 내리는 기분좋은 선선한 바람 사이로 가을이 여기저기서 알록달록 합니다.</p><img src="${process.env.PUBLIC_URL}/img/news/74.jpg" alt="" /><p class="text">농부의 정성이 절정에 닿은 들녁엔 햇살을 가득 안은 벼이삭이 황금빛으로 가을을 물들입니다.</p><p class="text">어머니의 품 처럼 넓고 포근한 지리산 자락, 온통 하얀 꽃송이 날리던 봄을 품고 계곡 물소리 가득했던 여름을 안고 풍성한 빛으로 반짝이는 단풍 은행 벼이삭들로 시원한 바람 불어오는 피아골 계곡에 깊어진 가을을 담아봅니다.</p><p class="text">멋진 시월의 건강한 가을 여행길 머무시는 동안 깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다. 문의사항은 010-8746-1409 로 전화주세요. 친절하게 안내해드리겠습니다. 감사합니다.</p>
		`,
	},
	{
		id: 6,
		caption: '여름지나 가을 맞이하는 갈대와 구름',
		title: '여행하기 참 좋은 계절 가을입니다.',
		date: '2022.09.12',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/60.jpg" alt="" /><p class="text">구름을 그린 푸른 빛 하늘이 높아지고</p><img src="${process.env.PUBLIC_URL}/img/news/61.jpg" alt="" /><p class="text">햇살을 안은 물 빛 계곡이 깊어지는 시간</p><img src="${process.env.PUBLIC_URL}/img/news/62.jpg" alt="" /><p class="text">맑은 바람이 뜨거운 태양을 달래 즐거웠던 여름 사이사이로 시원한 가을이 옵니다. </p><img src="${process.env.PUBLIC_URL}/img/news/63.jpg" alt="" /><p class="text">지리산 자락 어디라도 오르면 만날 하늘과 구름,</p><img src="${process.env.PUBLIC_URL}/img/news/64.jpg" alt="" /><p class="text">섬진강 변 어느 곳이라도 걸으면 마주할 풍경이 </p><p class="text">몸과 마음을 가을로 물들이며 잔잔해지는 여행길 소중한 사람들과 함께 가을 밤 별빛 머무르는 펜션 봉쥬르에서 깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다.</p><p class="text">홈페이지 및 네이버 예약 안내 참고하시어 예약 부탁드리며 문의 사항은 010-8746-1409 로 전화주세요. 친절하게 안내해드리겠습니다. 감사합니다.</p>
		`,
	},
	{
		id: 5,
		caption: '여름 햇살 아래 시원한 피아골',
		title: '뜨거운 여름 시원한 계곡으로의 여행~',
		date: '2022.06.07',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/50.jpg" alt="" /><p class="text">꽃송이 소복소복 피어나 온 봄을 하얗게 물들이다 그 예쁜 꽃 진자리에 연두잎 파릇파릇 물들며 초록빛 여름으로 짙어집니다.</p><img src="${process.env.PUBLIC_URL}/img/news/51.jpg" alt="" /><p class="text">따뜻한 기운으로 내리던 봄 햇살이 여름 태양으로 노랗게 반짝거리는 시간 뜨거움이 제대로인 여름</p><img src="${process.env.PUBLIC_URL}/img/news/52.jpg" alt="" /><p class="text">지리산 피아골 맑은 계곡 앞 봉쥬르에서 잔잔하게 불어오는 섬진강 시원한 바람은 덤으로 소중한 사람들과 함께 나누는 한 여름밤의 추억 어떨까요~</p><p class="text">여름맞이 예약진행중입니다. 홈페이지 참고하시어 전화문의 예약시 5% 할인혜택드리며, 네이버예약시 연박할인됩니다.</p><p class="text">깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다. 문의사항은 010-8746-1409 로 전화주세요. 친절하게 안내해드리겠습니다. 감사합니다.</p>`,
	},
	{
		id: 4,
		caption: '하얗게 피어난 벚꽃',
		title: '자연이 주는 감사함에 가득 담긴 봄 입니다.',
		date: '2022.03.12',
		html: `<p class="text">눈 뜨면 하늘 푸르름 가득한 곳,</p><img src="${process.env.PUBLIC_URL}/img/news/40.jpg" alt="" /><p class="text">귀 열면 맑은 계곡물 소리가 즐거운 곳,</p><img src="${process.env.PUBLIC_URL}/img/news/41.jpg" alt="" /><p class="text">한 걸음 나서 찰랑거리는 돌다리 지나며</p><img src="${process.env.PUBLIC_URL}/img/news/42.jpg" alt="" /><p class="text">소나무 향기 가득한 숲 오솔길</p><img src="${process.env.PUBLIC_URL}/img/news/43.jpg" alt="" /><p class="text">비로소 활짝 열려진 오감이 자연의 감사함을 가득 담은 곳, 어쩔 수 없었던 마음이 힘을 얻어 치유되어지는 시간들...</p><img src="${process.env.PUBLIC_URL}/img/news/44.jpg" alt="" /><p class="text">봄 오는 소리 따라 나선 길  발걸음이 머문 곳 자연이 베풀어 주는 아름다움으로 함께하는 여행길 곁으로 온 봄 만큼이나 따뜻하고 포근한 휴식으로 온전히사랑하는 사람과의 소중한 공간으로 천천히 누리시길~</p><p class="text">문의사항은 010-8746-1409 로 전화주세요. 친절하게 안내해드리겠습니다. 깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다. 감사합니다.</p>`,
	},
	{
		id: 3,
		caption: '봄 읍내로 가는 도로',
		title: '산수유 노랗게 피어날 봄 마중 소식 전합니다.',
		date: '2022.02.26',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/30.jpg" alt="" /><p class="text">자연으로 가는 길 구례, 읍내에서 바라본 노고단 설경이 언제쯤 봄일까 싶더니,</p><img src="${process.env.PUBLIC_URL}/img/news/31.jpg" alt="" /><p class="text">지리산 피아골 졸졸졸 흐르는 계곡 위로 까만 밤 내내 봄을 재촉하는 빗소리 통통통 흩어집니다. 그렇게 밝아온  섬진강변으로 뽀얗게 피어난 아침안개가, 반짝이는 햇살로 따뜻해진 한낮의 나른함에 머무는 걸 보면 아마도 조금씩 봄 오려나 봅니다. 시린 겨울 무던히 견뎌내고 노오란 산수유 빛으로 피어날 봄 마중길에 동행하겠습니다.</p><p class="text">문의사항은 010-8746-1409 로 전화주세요. 친절하게 안내해드리겠습니다. 깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다. 감사합니다.</p>`,
	},
	{
		id: 2,
		caption: '섬진강 피아골계곡',
		title: '겨울여행 시즌 이벤트 할인행사 안내드립니다.',
		date: '2021.12.23',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/20.jpg" alt="" /><p class="text">안녕하세요 봉쥬르펜션입니다.</p><p class="text">겨울 추위가 언제나 그렇듯 2~ 3일은 매섭게 춥다가도  3~ 4일은 조금 누그러들었다가 그러길 반복하며  조용히 또 저물어갑니다. 지리산 피아골 맑은 계곡물로 시리도록 투명해지는 마음 있어 그 자연이 고맙고  잔잔히 피어오른 섬진강 물빛으로 일렁이는 삶의 여운이 느껴져 그 자연이 경이로와집니다.</p><img src="${process.env.PUBLIC_URL}/img/news/21.jpg" alt="" /><p class="text">새해로 맞이 했을 지난 시간들을 차근차근 돌아보고 한해동안의 수고로움을 위로하며 얼마남지 않은 시간들 따뜻하게 마무리시길 바라며 겨울여행 시즌 이벤트 할인행사 진행합니다.</p><p class="text">문의사항은 010-8746-1409 로 전화주세요. 친절하게 안내해드리겠습니다. 감사합니다.</p>`,
	},
	{
		id: 1,
		caption: '겨울 펜션 데크너머로 보이는 풍경',
		title: '전화 예약 환영합니다.',
		date: '2021.12.13',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/10.jpg" alt="" /><p class="text">안녕하세요. 봉쥬르펜션입니다.</p><p class="text">지리산 피아골의 겨울이 조금씩 차가와지고 섬진강변 물안개도 겨울빛으로 뽀얗게 시려집니다. 사랑하는 가족 친구 연인과 함께하는 겨울여행은 지리산 섬진강변의 저물어가는 정취 느끼며 펜션 봉쥬르에서 따뜻하게 마무리 해 보세요. 깨끗하고 편안한 휴식일수 있도록 정성을 다하겠습니다. 전화로  문의해 주세요. 친절하게 안내해 드리겠습니다.</p><p class="text">문의전화 : 010-8746-1409</p>`,
	},
	{
		id: 0,
		caption: '새단장 마친 봉쥬르펜션',
		title: '구례 봉쥬르펜션 리뉴얼 오픈합니다.',
		date: '2021.12.10',
		html: `<img src="${process.env.PUBLIC_URL}/img/news/00.jpg" alt="" /><p class="text">안녕하세요 봉쥬르펜션입니다.</p><p class="text">지리산 피아골 계곡의 맑은 공기와 잔잔히 흐르는 섬진강변의 넉넉함을 추억하려는 손님들께 청결하고 편안한 휴식이 될 수 있도록 정성을 다하겠습니다.</p>`,
	},
];
// 뉴스 끝

// 주변소개 시작
const trevelData = [
	{
		type: '구례10경',
		name: '지리산국립공원(지리산 노고단)',
		contentId: 127590,
	},
	{ type: '구례10경', name: '지리산정원', contentId: 2737314 },
	{ type: '구례10경', name: '지리산호수공원', contentId: 2616165 },
	{ type: '구례10경', name: '섬진강 대나무숲길', contentId: 2714103 },
	{ type: '구례10경', name: '수락폭포', contentId: 126638 },
	{ type: '구례10경', name: '화엄사', contentId: 127923 },
	{ type: '구례10경', name: '천은사', contentId: 126366 },
	{ type: '구례10경', name: '사성암', contentId: 317559 },
	{ type: '구례10경', name: '운조루 고택', contentId: 128994 },
	{
		type: '구례10경',
		name: '구례5일장 / 구례전통시장(3, 8일)',
		contentId: 132057,
	},
	{ type: '구례10경', name: '한국압화박물관', contentId: 2616175 },
	{ type: '구례10경', name: '지리산역사관', contentId: 1331286 },
	{ type: '구례10경', name: '섬진강어류생태관', contentId: 2787957 },
	{ type: '역사/문화', name: '구례향제줄풍류전수관', contentId: 130559 },
	{ type: '역사/문화', name: '구례향교', contentId: 1954835 },
	{ type: '역사/문화', name: '매천사', contentId: 231912 },
	{ type: '역사/문화', name: '석주관성', contentId: 1624185 },
	{ type: '역사/문화', name: '곡전재', contentId: 2607706 },
	{ type: '역사/문화', name: '쌍산재', contentId: 2605430 },
	{ type: '역사/문화', name: '연곡사', contentId: 126363 },
	// { type: '역사/문화', name: '구례잔수농악', contentId:  },
	{ type: '자연/힐링', name: '피아골계곡', contentId: 126265 },
	{ type: '자연/힐링', name: '오미마을', contentId: 2784753 },
	{ type: '자연/힐링', name: '당동마을 (구례예술인마을)', contentId: 2794637 },
	{ type: '자연/힐링', name: '자연드림파크', contentId: 2599929 },
	{ type: '자연/힐링', name: '섬진강', contentId: 128086 },
	{ type: '자연/힐링', name: '섬진강 벚꽃길', contentId: 2648994 },
	{ type: '자연/힐링', name: '지리산 반야봉', contentId: 716410 },
	{ type: '체험/레포츠', name: '지리산 치즈랜드', contentId: 2616164 },
	{ type: '체험/레포츠', name: '섬진강 래프팅', contentId: 2787129 },
	{ type: '체험/레포츠', name: '동편제 판소리전수관', contentId: 130558 },
	{ type: '체험/레포츠', name: '반달가슴곰 생태학습장', contentId: 2614224 },
	// { type: '체험/레포츠', name: '지리산 스카이런', contentId:  },
	// { type: '체험/레포츠', name: '지리산 수상레저', contentId:  },
	// { type: '문화재', name: '산수유꽃 축제', contentId: 506398 },
];
// 주변소개 끝

// 자주하는질문 시작
// { name: '예약/결제', type: 'reservation' },{ name: '이용안내', type: 'use' },{ name: '기타', type: 'else' },
const faqData = [
	// 예약/결제
	{
		type: 'reservation',
		question: '봉쥬르펜션의 예약은 어떻게 해야하나요?',
		answer: `<ul class="list-style"><li class="bul">홈페이지 실시간예약, 네이버예약, 떠나요, 여기어때, 야놀자를 통해서 예약을 하실 수 있습니다.</li><li class="bul">당일 현장에서 객실잔여분이 있으면 관리실 통해서 예약이 가능합니다.</li></ul>`,
	},
	{
		type: 'reservation',
		question: '전화예약 가능한가요?',
		answer: `<ul class="list-style"><li class="bul">전화예약 가능합니다.</li><li class="bul">010-8746-1409로 전화나 문자 주시면 친절하게 답변해드리겠습니다.</li></ul>`,
	},
	{
		type: 'reservation',
		question: '체크인 및 체크아웃 시간은 어떻게되나요?',
		answer: `<ul class="list-style"><li class="bul">체크인 시간은 오후 3시입니다.</li><li class="bul">체크아웃 시간은 오전 11시입니다.</li><li class="bul">퇴실 시에 관리실 앞의 열쇠보관함에 객실 열쇠를 넣어주시길 바랍니다.</li><li class="bul">퇴실 시에는 분리수거 및 정리 정돈 부탁드립니다.</li></ul>`,
	},
	{
		type: 'reservation',
		question: '기준인원 초과 추가요금은 어떻게되나요?',
		answer: `<ul class="list-style"><li class="bul">추가요금<div class="table-wrap"><table><tbody><tr><th class="alC">성인·아동</th><td>20,000원</td></tr><tr><th class="alC">유아</th><td>10,000원</td></tr><tr><th class="alC">반려동물</th><td><ul class="list-style"><li class="bul">5kg미만 10,000원</li><li class="bul">5~20kg 20,000원</li><li class="bul">20kg 이상 30,000원</li></ul></td></tr></tbody></table></div></li><li class="bul">인원추가요금은 추가침구등의 비용이 포함됩니다.</li><li class="bul">예약시 안내드린대로 객실별 최대인원을 초과할수 없으며, 최대인원내에서 인원추가 또는 인원변동이 가능합니다.</li><li class="bul">기준인원 초과시 최대인원을 초과할수 없으며, 최대인원 초과시는 예약당일 입실하실수 없으며, 객실요금은 환불되지 않습니다.</li><li class="bul">봉쥬르펜션 모든 객실은 기준인원이 사용하기 가장 적합하며, 최대인원은 수용가능한 최대치입니다.</li><li class="bul">유아 자녀를 포함하여 최대인원은 초과하실수 없습니다.</li></ul>`,
	},
	{
		type: 'reservation',
		question: '반려동물 동반이 가능한가요?',
		answer: `<ul class="list-style"><li class="bul">반려동물 동반이 가능합니다. 하기 주의사항을 확인해주시기 바랍니다.</li><li class="bul">추가요금<ul><li class="hyp">5kg미만 10,000원</li><li class="hyp">5~20kg 20,000원</li><li class="hyp">20kg 이상 30,000원</li></ul></li><li class="bul">야외활동시 목줄 착용 부탁드리며 배설물 청결하게 처리 부탁드립니다.</li><li class="bul">지나친 소음으로 다른 객실에 피해가 되지않도록 주의 부탁드립니다.</li></ul><ul class="list-style"><li class="star">애견용품은 구비되어 있지 않으니 직접 가지고 오셔야 합니다.</li></ul>`,
	},
	{
		type: 'reservation',
		question: '바베큐 이용요금은 어떻게되나요? 객실별 바베큐장인가요?',
		answer: `<ul class="list-style"><li class="bul">바베큐 이용요금은 20,000원 입니다.</li><li class="bul">숯, 철망 추가시 5,000원 입니다.</li><li class="bul">각 객실 바로앞 데크에 개별 바베큐장이 있어 편하게 이용하실 수 있습니다.</li></ul>`,
	},
	{
		type: 'reservation',
		question: '유아의 나이는 몇살까지인가요?',
		answer: `<ul class="list-style"><li class="bul">유아는 만 2세 이상 만 6세 이하(미취학아동)의 연령까지입니다.</li></ul>`,
	},
	{
		type: 'reservation',
		question: '환불규정은 어떻게되고 예약 객실변경은 가능한가요?',
		answer: `
		<ul class="list-style"><li class="bul">환불규정<div class="table-wrap"><table><thead class="alC"><tr><td>(%)</td><th>취소 수수료</th><th>환불</th></tr></thead><tbody class="alC"><tr><th>이용당일</th><td>100</td><td>0</td></tr><tr><th>1일전</th><td>90</td><td>10</td></tr><tr><th>2일전</th><td>80</td><td>20</td></tr><tr><th>3일전</th><td>70</td><td>30</td></tr><tr><th>4일전</th><td>60</td><td>40</td></tr><tr><th>5일전</th><td>50</td><td>50</td></tr><tr><th>6일전</th><td>40</td><td>60</td></tr><tr><th>7일전</th><td>30</td><td>70</td></tr><tr><th>8일전</th><td>20</td><td>80</td></tr><tr><th>기본</th><td>10</td><td>90</td></tr></tbody></table></div></li><li class="bul">예약 후 펜션이나 객실 변경은 해당 예약 취소 후 다시 예약하셔야 합니다.</li><li class="bul">예약 변경을 위한 취소 시에도 취소 수수료가 부과 되니 신중하게 예약하시기 바랍니다.</li></ul>`,
	},
	{
		type: 'reservation',
		question: '계약금제도가 있나요?',
		answer: `<ul class="list-style"><li class="bul">봉쥬르펜션은 계약금 제도가 없습니다.</li><li class="bul">객실 가격은 전액 결제 하셔야 예약이 확정됩니다.</li><li class="bul">추가인원 및 기타 요금은 입실시 현장결제 가능합니다.</li></ul>`,
	},
	{
		type: 'reservation',
		question: '예약하고 싶은 객실이 마감되었어요. 대기자 시스템은 없나요?',
		answer: `<ul class="list-style"><li class="bul">봉쥬르펜션은 대기자 시스템이 없습니다.</li><li class="bul">객실 취소 시 바로 연락드리기 어려우니 양해 부탁드립니다.</li><li class="bul">온라인으로 실시간 예약현황이 반영되오니 살펴보시고 예약신청을 해주시기 바랍니다.</li></ul>`,
	},
	{
		type: 'use',
		question: '근처에 마트나 편의점 있나요?',
		answer: `<ul class="list-style"><li class="bul">펜션에서 차량 5분거리 화개장터, 하나로마트, 24시편의점이 있습니다.</li><li class="bul">가까운 편의점은 다리 건너 도보 10분정도에 위치해있습니다.<ul><li class="star">24시 편의점이 아닌 작은 슈퍼입니다.</li></ul></li></ul>`,
	},
	{
		type: 'use',
		question: '객실 TV로 넷플릭스/티빙등 이용가능한가요?',
		answer: `<ul class="list-style"><li class="bul">봉쥬르펜션 TV는 KT올레 셋톱박스가 달려있습니다.</li><li class="bul">티비에서 직접 넷플릭스/티빙등을 이용할 수 없습니다.</li><li class="bul">다만, KT올레TV를 통해 개별적으로 넷플릭스로 접속하는 경우가 있으며, 이는 펜션에서 방법을 안내드리거나 접속을 도와드리지 않으며, 개별적으로 접속하셔야 합니다.</li><li class="bul">넷플릭스 접속후 반드시 로그아웃을 하셔야하며, 로그아웃을 하시지 않는 경우로 인한 피해는 펜션에서 책임지지 않습니다</li></ul>`,
	},
	{
		type: 'use',
		question: '객실비품은 어떻게되나요?',
		answer: `<ul class="list-style"><li class="bul">주방조리도구(밥솥, 냄비, 후라이팬, 도마, 칼, 가위등)</li><li class="bul">식기류(수저, 밥/국 그릇, 쟁반등)</li><li class="bul">샤워용품(수건, 샴푸, 린스, 바디워시, 치약), 헤어드라이기</li><li class="bul">TV, 에어컨, 냉장고, 식탁과 의자</li></ul><ul class="list-style"><li class="star">일회용품(생수, 종이컵, 칫솔, 면도기등)과 양념(식용유, 간장, 소금, 참기름등)은 제공되지 않습니다.</li><li class="star">와인잔, 고데기는 구비되어있지 않습니다.</li></ul>`,
	},
	{
		type: 'use',
		question: '튜브대여 또는 튜브 바람넣을 수 있나요?',
		answer: `<ul class="list-style"><li class="bul">여름용품(튜브, 보트, 스노쿨링장비등)은 구비되어 있지 않습니다.</li><li class="bul">관리실 문의해주시면 수동펌프 대여가능합니다.</li></ul>`,
	},
	{
		type: 'use',
		question: '아기의자나 유아용 식기가 있나요?',
		answer: `<ul class="list-style"><li class="bul">아기의자, 유아용 식기 등 유아용 물품은 비치되어 있지 않습니다.</li></ul>`,
	},
	{
		type: 'use',
		question: '조식 제공되나요?',
		answer: `<ul class="list-style"><li class="bul">조식은 제공되지 않습니다.</li><li class="bul">식당이나 카페시설이 없어 제공이 어렵습니다.</li></ul>`,
	},
	{
		type: 'else',
		question: '객실 위치나 관리실의 위치는 어떻게되나요?',
		answer: `<i class="overview"></i><ul class="list-style"><li class="bul">상단의 객실배치도를 확인하시기 바랍니다.</li></ul><ul class="list-style"><li class="star"><a href=${process.env.PUBLIC_URL}/img/room/room_overview.jpg target="_blank" rel="noreferrer" title="새창">크게보기</a></li></ul>`,
	},
	{
		type: 'else',
		question: '주차장 공간은 어떻게되나요?',
		answer: `<ul class="list-style"><li class="bul">주차장은 객실별 1~2대 주차 가능합니다.</li><li class="bul">펜션이용자를 위한 무료주차장이며, 주차장내 사고등에 피해에 대해 책임을 지지 않습니다</li><li class="bul">위치는 상기 "객실 위치나 관리실의 위치는 어떻게되나요?" 질문을 확인해주시기 바랍니다.</li></ul>`,
	},
	{
		type: 'else',
		question: '객실명 뒤의 번호별로 객실에 차이가 있나요? (ex. Sky4, Cloud3...)',
		answer: `<ul class="list-style"><li class="bul">객실의 위치만 차이가 있을 뿐 크기나 시설은 동일합니다.</li><li class="bul">위치는 상기 "객실 위치나 관리실의 위치는 어떻게되나요?" 질문을 확인해주시기 바랍니다.</li></ul>`,
	},
	{
		type: 'else',
		question: '다른 문의 사항은 어떻게 여쭤보면 될까요?',
		answer: `<ul class="list-style"><li class="bul">문의사항은 010-8746-1409로 전화주시면 안내해드리겠습니다.</li><li class="bul">깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다. 감사합니다.</li></ul>`,
	},
	// {
	// 	type: 'use',
	// 	question: '결제방법(계좌이체? 카드?)안내? 현금영수증 발급?',
	// 	answer: `1. 계좌이체로 결제(예약시 문자로 결제정보 전송), 예약시 카드결제는 불가합니다. 결제(입금)완료후 예약일 카드결제변경은 가능하며, 체크인시 프런트로 변경요청하시면 됩니다. 예약시 입금단계에서 부분입금(예약금) 결제는 불가합니다.2. 현금영수증은 체크인시 프런트로 요청, 예약취소, 부가서비스 변경등 결제금액이 변동될수 있어 입금일 기준이 아닌 입실일 기준으로 프런트에서 발행됩니다. 소득공제용은 전화번호등으로 신청하시고, 사업자지출증빙용은 사업자번호로 신청하시면 됩니다`,
	// },
	// {
	// 	type: 'else',
	// 	question: '무통장입금은 언제까지 해야하나요?',
	// 	answer: `무통장입금의 입금기한은 예약신청일 다음날 오후 6시까지입니다.`,
	// },
	// {
	// 	type: 'else',
	// 	question: '카드결제가 되지 않아요.',
	// 	answer: `카드결제가 되지 않는 문제는 저희 상담과 홈페이지 측에서는 해드릴 수 있는 것이 없습니다.
	// 	※다음과 같은 이유가 아니신지 다시 한번 체크해 보시기 바랍니다.
	// 	1. 카드 정보를 잘못 기입했을 경우
	// 	2. 카드의 한도가 초과했을 경우
	// 	3. 예약신청하는 컴퓨터나 핸드폰의 오류(다른 기기로 시도해 보시기 바랍니다.)
	// 	4. 카드사의 전산 오류일 경우(사용하시는 카드사가 전산 작업중인 경우가 있습니다. 이경우는 문의주시면 알려드립니다.)`,
	// },
	// 	{
	// 		type: 'else',
	// 		question: '카드결제를 했는데 영수증을 받고 싶어요.',
	// 		answer: `카드결제 영수증은 이메일로 전송해드릴 수 있습니다. 메일주소를 알려주시면 매출전표를 전송해드립니다.`,
	// 	},
	// 	{
	// 		type: 'else',
	// 		question: '현금영수증을 발행하고 싶어요.',
	// 		answer: `현금영수증 발행을 요청하면 예약자분 번호로 현금영수증을 발행해 드립니다.
	// (별도로 다른 번호로 발급을 원하시면 반드시 문자로 보내주시기 바랍니다.)`,
	// 	},
	// {
	// 	type: 'else',
	// 	question: '예약이 된건지 모르겠어요.',
	// 	answer: `예약 후 확인 문자를 받으셨다면 예약이 확정되신 것입니다.
	// 	예약 확인 문자는 예약시에 적어주신 번호로 발송됩니다. 그 번호로 전송된 MMS문자를 확인부탁드립니다.`,
	// },
	// {
	// 	type: 'else',
	// 	question: '홈페이지에서 예약을 했는데 예약확인이 되지 않아요.',
	// 	answer: `홈페이지에서 예약하신 경우, 예약시에 기입하셨던 이름과 핸드폰번호를 정확히 쓰셔야 조회가 가능하십니다.
	// 	간혹 이름에 오타가 생겼거나 핸드폰번호를 잘못 기입하여 조회를 하지 못하는 고객님께서는 저희에게 연락주시면 올바르게 수정해드립니다.`,
	// },
];
// 자주하는질문 끝

// 객실소개 시작
const roomData = [
	{
		id: 0,
		en: 'vally',
		ko: '밸리',
		count: ['v1', 'v2'],
		countRoom: ['밸리1', '밸리2'],
		images: ['v0', 'v1', 'v2', 'v3', 'v4', 'v5'],
		info: '넓은 거실과 깨끗한 침구로 꾸며진 트윈더블베드가 준비된 침실이 있으며 펜션 1층에 위치하여 창을 열면 지리산 피아골 계곡의 물소리와 섬진강변의 아침햇살이 가득한 객실입니다.',
		personnel: '기준 4인 / 최대 8인',
		config: '침실 거실 주방 화장실 개별바베큐장',
		bed: '더블',
		size: '거실 + 객실 / 15평 (50㎡)',
		normalPrice: 120000,
		peakPrice: 200000,
	},
	{
		id: 1,
		en: 'mountain',
		ko: '마운틴',
		count: ['m'],
		countRoom: ['마운틴'],
		images: ['m0', 'm1', 'm2', 'm3', 'm4', 'm5'],
		info: '깨끗한 침구로 꾸며진 더블베드가 준비된 침실겸 거실이 있으며 펜션 1층에 위치하여 창을열면 지리산 피아골 계곡의 물소리와 섬진강변의 아침햇살이 가득한 객실입니다.',
		personnel: '기준 2인 / 최대 6인',
		config: '침실 거실 주방 화장실 개별바베큐장',
		bed: '더블',
		size: '원룸형 / 13평 (43㎡)',
		normalPrice: 90000,
		peakPrice: 200000,
	},
	{
		id: 2,
		en: 'sky',
		ko: '스카이',
		count: ['s1', 's2', 's3', 's4'],
		countRoom: ['스카이1', '스카이2', '스카이3', '스카이4'],
		images: ['s0', 's1', 's2', 's3', 's4', 's5'],
		info: '넓은 거실과 깨끗한 침구로 꾸며진 복층 다락방 침실 구조로 펜션 2층에 위치해 있어 창을 열면 지리산 피아골 계곡과 섬진강변의 뷰가 그림처럼 펼쳐진 전경이 아름다운 객실입니다.',
		personnel: '기준 4인 / 최대 6인',
		config: '침실 거실 주방 화장실 개별바베큐장',
		bed: '더블',
		size: '거실 + 복층 / 12평 (40㎡)',
		normalPrice: 90000,
		peakPrice: 200000,
	},
	{
		id: 3,
		en: 'cloud',
		ko: '클라우드',
		count: ['c1', 'c2', 'c3', 'c4'],
		countRoom: ['클라우드1', '클라우드2', '클라우드3', '클라우드4'],
		images: ['c0', 'c1', 'c2', 'c3', 'c4', 'c5'],
		info: '깨끗한 침구로 단정하게 정리된 원룸형 구조로 펜션 2층에 위치해 있어 창을 열면 지리산 피아골 계곡과 섬진강변의 뷰가 그림처럼 펼쳐진 전경이 아름다운 객실입니다.',
		personnel: '기준 2인 / 최대 3인',
		config: '침실 거실 주방 화장실 개별바베큐장',
		bed: '더블',
		size: '원룸형 / 10평 (33㎡)',
		normalPrice: 50000,
		peakPrice: 150000,
	},
];
// 객실소개 끝

// 편의시설 시작
const facilityData = [
	{
		id: 0,
		name: '제1 주차장',
		count: 'p1',
		info: '펜션 입구에 위치한 주차장으로 객실동(스카이1·2, 클라우드1·2, 밸리1·2, 마운틴)의 객실과 가까운 주차장입니다. 마운틴 혹은 밸리2 손님분들은 제 2주차장이 더 가까우니 해당 주차장 이용 추천드립니다.',
	},
	{
		id: 1,
		name: '제2 주차장',
		count: 'p2',
		info: '객실동과 관리동 사이에 위치한 주차장으로 객실동(스카이1·2, 클라우드1·2, 밸리1·2, 마운틴), 관리동(스카이3·4, 클라우드3·4) 모두 가깝습니다. 관리동 손님분들은 제 3주차장이 제일 가까우니 해당 주차장 이용 추천드립니다.',
	},
	{
		id: 2,
		name: '제3 주차장',
		count: 'p3',
		info: '관리동(스카이3·4, 클라우드3·4) 뒤에 위치한 주차장으로 2층에 있어 관리동 손님분들만 이용 추천드립니다. 다만 주차가능 대수가 적어 확인후 이용 부탁드립니다.',
	},
	{
		id: 3,
		name: '관리실',
		count: 'o',
		info: '입구로 들어오셔서 좌측 길 지나면 관리동 건물에 관리실이 있습니다. 입실 시에 관리실 방문 후 이용 부탁드립니다.',
	},
	{
		id: 4,
		name: '분리수거장',
		count: 't',
		info: '관리실 앞에 분리수거장 위치해 있어 퇴실시 분리수거 부탁드립니다.',
	},
];
// 편의시설 끝

export { faqData, trevelData, newsData, roomData, facilityData };

import ReactModal from 'react-modal';

import { newsData } from '../../assets/js/Data';

import { useState, useRef } from 'react';

const News = ({ onClose, dataId }) => {
	const [curId, setCurId] = useState(dataId);
	const [curData, setCurData] = useState(newsData.find((data) => data.id === dataId));

	const modalContent = useRef();
	// 이전글
	const handleClickPrev = () => {
		setCurId((prev) => prev - 1);
		setCurData(newsData.find((data) => data.id === curId - 1));
		modalContent.current.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};
	// 다음글
	const handleClickNext = () => {
		setCurId((prev) => prev + 1);
		setCurData(newsData.find((data) => data.id === curId + 1));
		modalContent.current.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	// 닫기
	const [close, setClose] = useState(false);
	const handleClickCancel = (e) => {
		setClose(true);
		setTimeout(() => {
			onClose();
			setCurData();
			setCurId();
		}, 500);
	};

	return (
		<ReactModal isOpen className={`Modal.defaultStyles ${close ? 'close' : ''}`}>
			<div className="modal news-modal">
				<div className="modal-title">
					<h3>{curData.title}</h3>
					<p>봉쥬르 관리자 | {curData.date}</p>
				</div>
				<div className="modal-content" dangerouslySetInnerHTML={{ __html: curData.html }} ref={modalContent}></div>
				<div className="modal-button-area">
					<button onClick={handleClickPrev} disabled={curId === 0}>
						이전글
					</button>
					<button onClick={handleClickNext} disabled={curId === newsData.length - 1}>
						다음글
					</button>
				</div>
				<button className="modal-close" onClick={handleClickCancel}>
					<span className="hide">닫기</span>
				</button>
			</div>
		</ReactModal>
	);
};

export default News;

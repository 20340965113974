import React, { useState, useEffect, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Navigation, Thumbs } from 'swiper';

function About() {
	// 스와이퍼
	const [thumbsSwiper, setThumbsSwiper] = useState(null);
	const swiperParams = {
		modules: [EffectFade, Navigation, Thumbs],
		loop: true,
		grabCursor: true,
		navigation: true,
		thumbs: { swiper: thumbsSwiper },
		effect: 'fade',
	};
	const swiperThumbsParams = {
		onSwiper: setThumbsSwiper,
		loop: true,
		slidesPerView: 8,
	};

	// 네이버 지도 api
	const mapElement = useRef(null);
	const { naver } = window;
	useEffect(() => {
		const location = new naver.maps.LatLng(35.202015, 127.60101);
		const mapOptions = {
			center: location,
			zoom: 20,
			zoomControl: true,
		};

		const map = new naver.maps.Map(mapElement.current, mapOptions);
		new naver.maps.Marker({
			position: location,
			map,
		});
	});

	// 랜덤 이미지 배열 생성
	// const [randomIndexArray, setRamdomIndexArray] = useState([]);
	// useEffect(() => {
	// 	const selectIndex = (totalIndex, ArrayIndex) => {
	// 		let customArray = [];
	// 		for (let i = 0; i < ArrayIndex; i++) {
	// 			let randomNum = Math.floor(Math.random() * totalIndex);
	// 			if (customArray.indexOf(randomNum) === -1) {
	// 				customArray.push(randomNum);
	// 			} else {
	// 				i--;
	// 			}
	// 		}
	// 		setRamdomIndexArray(customArray);
	// 	};
	// 	selectIndex(145, 16);
	// }, []);

	// 계절포매팅
	const [season, setSeason] = useState();
	useEffect(() => {
		const curMonth = new Date().getMonth() + 1;
		if (curMonth >= 4 && curMonth <= 10) {
			setSeason('ss');
		} else {
			setSeason('fw');
		}
	}, []);

	return (
		<div className="content about">
			<div className="gallery">
				<Swiper {...swiperParams} className="gallery-images">
					{new Array(16).fill(0).map((img, i) => (
						<SwiperSlide key={i}>
							<a className="btn-origin" href={`${process.env.PUBLIC_URL}/img/about_${season}/${i}.jpg`} target="_blank" rel="noreferrer" title="새창">
								원본보기
							</a>
							<img src={`${process.env.PUBLIC_URL}/img/about_${season}/${i}.jpg`} alt="" />
						</SwiperSlide>
					))}
				</Swiper>
				<Swiper {...swiperThumbsParams} className="gallery-thumbs">
					{new Array(16).fill(0).map((img, i) => (
						<SwiperSlide key={i}>
							<img src={`${process.env.PUBLIC_URL}/img/about_${season}/${i}.jpg`} alt="" />
						</SwiperSlide>
					))}
				</Swiper>
			</div>
			<div className="visual">
				<h2 className="title">지리산 피아골 계곡 섬진강 뷰 펜션 봉쥬르</h2>
				<p className="h3 active">눈 뜨면 하늘 푸르름 가득한 곳, 귀 열면 맑은 계곡물 소리가 즐거운 곳</p>
				<p className="h3">한 걸음 나서서 찰랑거리는 돌다리 지나면 소나무 향기 가득한 숲 오솔길</p>
				<p className="h3">비로소 활짝 열려진 오감이 자연의 감사함을 가득 담은 곳</p>
				<p className="h3 sub-title">마음이 힘을 얻어 치유되는 시간...</p>
				<p>자연이 베풀어 주는 아름다움으로 사랑하는 사람들과 함께하는 여행길 소중한 공간으로 추억되시길 바랍니다.</p>
				<p>
					문의사항은 <b>010-8746-1409</b>로 전화주세요. 친절하게 안내해드리겠습니다.
				</p>
				<p>깨끗하고 편안한 휴식일 수 있도록 정성을 더하겠습니다. 감사합니다.</p>
			</div>

			<div className="waytocome">
				<h2 className="title">펜션정보</h2>
				<div ref={mapElement} style={{ height: '40rem' }} />
				<h3 className="sub-title">전라남도 구례군 토지면 섬진강대로 4310-20 봉쥬르펜션</h3>
				<table>
					<colgroup>
						<col width="16%" />
					</colgroup>
					<tbody>
						<tr>
							<th>위치</th>
							<td>은어마을 펜션단지 입구 첫번째 위치한 지리산 피아골 계곡 펜션입니다.</td>
						</tr>
						<tr>
							<th>전화번호</th>
							<td>010-8746-1409</td>
						</tr>
						<tr>
							<th>계좌정보</th>
							<td>
								<ul className="list-style">
									<li className="bul">계좌번호: 110035446630</li>
									<li className="bul">예금주: 이은영</li>
									<li className="bul">입금은행: 신한은행</li>
								</ul>
							</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div className="facilities">
				<h2 className="title">편의시설</h2>
				<ul className="facility-list">
					<li>
						<i className="icon-wifi"></i>
						<p>와이파이</p>
					</li>
					<li>
						<i className="icon-cooker"></i>
						<p>전기밥솥</p>
					</li>
					<li>
						<i className="icon-parking"></i>
						<p>주차장</p>
					</li>
					<li>
						<i className="icon-bbq"></i>
						<p>바베큐</p>
					</li>
					<li>
						<i className="icon-shampoo"></i>
						<p>샴푸, 바디워시</p>
					</li>
					<li>
						<i className="icon-air"></i>
						<p>에어컨</p>
					</li>
					<li>
						<i className="icon-refrigerator"></i>
						<p>냉장고</p>
					</li>
					<li>
						<i className="icon-shower"></i>
						<p>객실샤워실</p>
					</li>
					<li>
						<i className="icon-dryer"></i>
						<p>드라이기</p>
					</li>
					<li>
						<i className="icon-dog"></i>
						<p>반려견동반</p>
					</li>
					<li>
						<i className="icon-cooking"></i>
						<p>객실내취사</p>
					</li>
					<li>
						<i className="icon-microwave"></i>
						<p>전자레인지</p>
					</li>
				</ul>
			</div>
		</div>
	);
}

export default About;

import useModals from '../components/hooks/useModals';
import { modals } from '../components/modal/Modals';

import { useState, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { roomData } from '../assets/js/Data';

import { DateRange } from 'react-date-range';
import { format, addDays } from 'date-fns';
import { ko } from 'react-date-range/dist/locale';

function Reservation() {
	const { openModal } = useModals();
	const roomOpen = (e) => {
		openModal(modals.Room, {
			dataId: Number(e.currentTarget.dataset.id),
		});
	};

	const ddnayo = true;

	const [selectedDate, setSelectedDate] = useState([
		{
			startDate: addDays(new Date(), 0),
			endDate: addDays(new Date(), 1),
			key: 'selection',
		},
	]);
	const dateRangeOpt = {
		ranges: selectedDate,
		months: 2,
		direction: 'horizontal',
		locale: ko,
		color: null,
		rangeColors: [null],
		minDate: addDays(new Date(), 0),
		maxDate: addDays(new Date(), 90),
		onChange: (item) => setSelectedDate([item.selection]),
		dateDisplayFormat: 'yy년 M월 d일',
		monthDisplayFormat: 'yy년 M월',
		// startDatePlaceholder: '입실일을 입력해주세요',
		// endDatePlaceholder: '퇴실일을 입력해주세요',
		// fixedHeight: true,
		showMonthAndYearPickers: false,
		editableDateInputs: false,
	};

	const [checkedList, setCheckedList] = useState([]);
	const [sumPrice, setSumPrice] = useState(0);
	const onCheckedItem = (checked, item, value) => {
		if (checked) {
			setCheckedList((prev) => [...prev, item]);
			setSumPrice((prev) => prev + parseInt(value));
		} else if (!checked) {
			setCheckedList(checkedList.filter((el) => el !== item));
			setSumPrice((prev) => prev - parseInt(value));
		}
	};

	// 값 설정
	const returnValue = {
		range: `${format(selectedDate[0].startDate, 'yy년 M월 d일')}~${format(selectedDate[0].endDate, 'yy년 M월 d일')}`,
		night: Math.ceil(Math.abs(selectedDate[0].endDate.getTime() - selectedDate[0].startDate.getTime()) / (1000 * 60 * 60 * 24)),
		rooms: checkedList.join(', '),
		price: sumPrice,
	};

	return (
		<div className="content reservation">
			<div className="info">
				<h2 className="title">예약안내</h2>
				<table>
					<colgroup>
						<col width="30%" />
					</colgroup>
					<tbody>
						<tr>
							<th>입실시간</th>
							<td>당일 15:00</td>
						</tr>
						<tr>
							<th>퇴실시간</th>
							<td>익일 11:00</td>
						</tr>
						<tr>
							<th>인원 추가요금</th>
							<td>
								<ul className="list-style">
									<li className="bul">성인, 아동 20,000원</li>
									<li className="bul">유아 10,000원</li>
									<li className="bul">반려동물 5kg미만 10,000원, 5~20kg 20,000원, 20kg 이상 30,000원</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th>바베큐숯 이용요금</th>
							<td>
								<ul className="list-style">
									<li className="bul">
										기본 20,000원
										<ul>
											<li className="star">숯, 철망 추가시 5,000원</li>
										</ul>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<th>전기그릴 이용요금</th>
							<td>20,000원</td>
						</tr>
					</tbody>
				</table>
			</div>

			<div className="caution">
				<h2 className="title">예약/취소 시 유의사항</h2>
				<ul className="list-style">
					<li className="bul">보호자 동반 없는 미성년자는 예약 및 입실 불가 합니다.</li>
					<li className="bul">예약 시 신청 기준 인원은 명시되어있으며, 추가 인원에 대해서는 현장결제 바랍니다.</li>
					<li className="bul">예약 인원 초과로 인한 입실 거부 시 환불 되지 않으니 유의하시기 바랍니다.</li>
					<li className="bul">예약 후 펜션이나 객실 변경은 해당 예약 취소 후 다시 예약하셔야 합니다.</li>
					<li className="bul">예약 변경을 위한 취소 시에도 취소 수수료가 부과 되니 신중하게 예약하시기 바랍니다.</li>
					<li className="bul">퇴실 시에는 분리수거 및 정리 정돈 부탁드립니다.</li>
				</ul>
			</div>

			<div className="fee">
				<h2 className="title">객실 요금</h2>
				<div className="table-wrap">
					<table style={{ minWidth: '52rem' }}>
						<thead>
							<tr>
								<td className="alC" colSpan={2}>
									(원)
								</td>
								<th>Cloud</th>
								<th>Sky</th>
								<th>Mountain</th>
								<th>Vally</th>
							</tr>
						</thead>
						<tbody className="alC">
							<tr>
								<th className="alC" rowSpan={2}>
									비수기
								</th>
								<th className="alC">주중</th>
								<td>65,000</td>
								<td>95,000</td>
								<td>85,000</td>
								<td>125,000</td>
							</tr>
							<tr>
								<th className="alC">주말</th>
								<td>85,000</td>
								<td>135,000</td>
								<td>125,000</td>
								<td>165,000</td>
							</tr>
							<tr>
								<th className="alC" rowSpan={2}>
									준성수기
								</th>
								<th className="alC">주중</th>
								<td>80,000</td>
								<td>120,000</td>
								<td>120,000</td>
								<td>150,000</td>
							</tr>
							<tr>
								<th className="alC">주말</th>
								<td>100,000</td>
								<td>150,000</td>
								<td>150,000</td>
								<td>180,000</td>
							</tr>
							<tr>
								<th className="alC" rowSpan={2}>
									성수기
								</th>
								<th className="alC">주중</th>
								<td>150,000</td>
								<td>200,000</td>
								<td>200,000</td>
								<td>250,000</td>
							</tr>
							<tr>
								<th className="alC">주말</th>
								<td>150,000</td>
								<td>200,000</td>
								<td>200,000</td>
								<td>250,000</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div className="penalty">
				<h2 className="title">취소 위약금</h2>
				<div className="table-wrap">
					<table style={{ minWidth: '87.1rem' }}>
						<thead>
							<tr>
								<td className="alC">(%)</td>
								<th>이용당일</th>
								<th>1일전</th>
								<th>2일전</th>
								<th>3일전</th>
								<th>4일전</th>
								<th>5일전</th>
								<th>6일전</th>
								<th>7일전</th>
								<th>8일전</th>
								<th>기본</th>
							</tr>
						</thead>
						<tbody className="alC">
							<tr>
								<th>취소 수수료</th>
								<td>100</td>
								<td>90</td>
								<td>80</td>
								<td>70</td>
								<td>60</td>
								<td>50</td>
								<td>40</td>
								<td>30</td>
								<td>20</td>
								<td>10</td>
							</tr>
							<tr>
								<th>환불</th>
								<td>0</td>
								<td>10</td>
								<td>20</td>
								<td>30</td>
								<td>40</td>
								<td>50</td>
								<td>60</td>
								<td>70</td>
								<td>80</td>
								<td>90</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			{ddnayo ? (
				<div className="shortcut">
					<a href="https://booking.ddnayo.com/booking-calendar-status?accommodationId=14452" className="designed-border" target="_blank" rel="noreferrer" title="새창">
						<i className="border-top"></i>
						<h3 className="border-inner">실시간 예약</h3>
						<i className="border-bottom"></i>
					</a>
				</div>
			) : (
				<>
					<div className="book-date">
						<h2 className="title">실시간 예약</h2>
						<DateRange {...dateRangeOpt} />
					</div>
					<div className="book-room">
						<ul className="room-select">
							{roomData.map((room) => (
								<Fragment key={room.id}>
									{room.countRoom.map((each, i) => (
										<li key={i} className="checkbox">
											<input
												id={`${each}`}
												type="checkbox"
												value={room.peakPrice}
												onChange={(e) => {
													onCheckedItem(e.target.checked, e.target.id, e.target.value);
												}}
											/>
											<label htmlFor={`${each}`}>
												<p className="h4">{each}</p>
												<span className="price">{room.peakPrice.toLocaleString('ko-KR')}원</span>
												<button className="btn-detail" onClick={roomOpen} data-id={room.id}>
													<span className="hide">자세히보기</span>
												</button>
											</label>
										</li>
									))}
								</Fragment>
							))}
						</ul>
					</div>
					<div className="book-info">
						<table>
							<colgroup>
								<col width="30%" />
							</colgroup>
							<tbody>
								<tr>
									<th>기간</th>
									<td>{returnValue.range}</td>
								</tr>
								<tr>
									<th>숙박일</th>
									<td>
										{returnValue.night}박 {returnValue.night + 1}일
									</td>
								</tr>
								<tr>
									<th>예약 객실</th>
									<td>{returnValue.rooms === '' ? '선택된 객실이 없습니다.' : returnValue.rooms}</td>
								</tr>
								<tr>
									<th>가격</th>
									<td>{(parseInt(returnValue.price) * parseInt(returnValue.night)).toLocaleString('ko-KR')}원</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div className="designed-border btn-book">
						<i className="border-top"></i>
						<i className="border-bottom"></i>
						<Link to="/payment" state={{ bookData: returnValue }} className="border-inner">
							<span className="text">예약&middot;결제하기</span>
						</Link>
					</div>
				</>
			)}
			{/* <iframe src="https://booking.ddnayo.com/booking-calendar-status?accommodationId=14452" height="3221px"></iframe> */}
		</div>
	);
}

export default Reservation;

import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function Payment() {
	const location = useLocation();
	const bookData = location.state.bookData;

	// 아코디언
	const [opened, setOpened] = useState([]);
	const openAccord = (i) => {
		if (opened.includes(i)) {
			setOpened((prev) => [...prev].filter((el) => el !== i));
		} else {
			setOpened((prev) => [...prev, i]);
		}
	};
	const openAccordAll = () => {
		setOpened(
			Array(terms.length)
				.fill()
				.map((v, i) => i),
		);
	};
	const closeAccordAll = () => {
		setOpened([]);
	};

	// 약관
	const terms = [
		{
			title: '(필수) 이용시 유의사항에 동의',
			content: `보호자 동반 없는 미성년자는 예약 및 입실 불가 합니다.<br><br>예약 시 결제되는 금액은 기준인원에 대한 금액이며, 최대 인원 추가요금은 현장에서 결제 진행됩니다.<br><br>예약 인원 초과로 인한 입실 거부 시 환불 되지 않으니 유의하시기 바랍니다.<br><br>예약 후 펜션이나 객실 변경은 해당 예약 취소 후 다시 예약하셔야 합니다.<br><br>예약 변경을 위한 취소 시 에도 취소 수수료가 부과되오니 신중하게 예약하시기 바랍니다.<br><br>최대 인원이 2인인 커플룸의 경우 유아나 아동은 동반 입실은 추가 요금이 발생합니다.<br><br>퇴실 시에는 분리수거 및 정리 정돈 부탁 드립니다.`,
		},
		{
			title: '(필수) 취소수수료(예약시점과 무관한 이용일 기준)에 동의',
			content: `예약취소는 구매한 사이트 "예약확인/취소" 에서 가능합니다.<br><br>취소수수료는 예약시점과는 무관한 이용시작일 기준입니다.<br><br>환불시 결제하신 금액에서 취소수수료를 제외한 금액을 환불해 드립니다.<br><br>취소수수료는 결제금액이 아닌 예약금(객실요금+기타옵션요금) 기준으로 책정됩니다.<br><br>취소수수료가 100% 인 경우 전액 환불되지 않습니다.<br><br>수수료 내역은 아래와 같습니다.`,
		},
		{
			title: '(필수) 개인정보 수집 및 이용에 동의',
			content: `주식회사 떠나요는 숙박 예약에 필요한 최소한의 개인정보를 수집하고 있으며 동의받은 목적 외 용도로 사용하지 않습니다.`,
		},
		{
			title: '(필수) 개인정보 제3자제공에 동의',
			content: `주식회사 떠나요는 숙박 예약에 필요한 최소한의 개인정보를 제공하고 있으며 동의받은 목적 외 용도로 제공하지 않습니다.`,
		},
		{
			title: '(필수) 이용자가 미성년자가 아니며 성인임에 동의',
			content: `주식회사 떠나요는 숙박 예약에 필요한 최소한의 개인정보를 제공하고 있으며 동의받은 목적 외 용도로 제공하지 않습니다.`,
		},
	];
	const [allChecked, setAllChecked] = useState(false);
	const [checkedList, setCheckedList] = useState([]);
	let checkEach = (i) => {
		if (checkedList.includes(i)) {
			setCheckedList((prev) => [...prev].filter((el) => el !== i));
		} else {
			setCheckedList((prev) => [...prev, i]);
		}
	};
	const checkAll = () => {
		if (checkedList.length < 5) {
			setCheckedList(
				Array(5)
					.fill()
					.map((v, i) => i),
			);
			setAllChecked(true);
		} else {
			setCheckedList([]);
			setAllChecked(false);
		}
	};
	useEffect(() => {
		if (checkedList.length < 5) {
			setAllChecked(false);
		} else {
			setAllChecked(true);
		}
	}, [checkedList]);

	return (
		<div className="content payment">
			<div className="selected">
				<h2 className="title">선택객실</h2>
				<table>
					<colgroup>
						<col width="30%" />
					</colgroup>
					<tbody>
						<tr>
							<th>객실명</th>
							<td>{bookData.rooms}</td>
						</tr>
						<tr>
							<th>이용일</th>
							<td>{bookData.range}</td>
						</tr>
						<tr>
							<th>요금타입</th>
							<td>성수기 / 주중</td>
						</tr>
						<tr>
							<th>결제금액</th>
							<td className="price">{bookData.price}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="inp-info">
				<h2 className="title">예약자정보</h2>
				<table>
					<colgroup>
						<col width="120px" />
					</colgroup>
					<tbody>
						<tr>
							<th>
								<label htmlFor="inp01">예약자명</label>
							</th>
							<td>
								<div className="inp-cell name">
									<input type="text" id="inp01" />
									<span className="place-holder">예약자명</span>
								</div>
								<p className="chk">예약자명은 한글 또는 영문으로 2~10글자만 입력이 가능합니다.</p>
							</td>
						</tr>
						<tr>
							<th>
								<label htmlFor="inp02">연락처</label>
							</th>
							<td>
								<div className="inp-cell phone">
									<select>
										<option>010</option>
										<option>011</option>
										<option>017</option>
										<option>018</option>
										<option>019</option>
										<option>0502</option>
										<option>0503</option>
										<option>0504</option>
										<option>0507</option>
									</select>
									<input type="number" id="inp02" />
									<input type="number" id="inp02" />
								</div>
								<p className="chk">올바른 연락처를 입력해주세요.</p>
							</td>
						</tr>
						<tr>
							<th>
								<label htmlFor="inp03">이메일</label>
							</th>
							<td>
								<div className="inp-cell email">
									<input type="email" id="inp03" />
									<span className="place-holder">이메일</span>
								</div>
								<p className="chk">이메일을 입력해주세요.</p>
							</td>
						</tr>
						<tr>
							<th>
								<label htmlFor="inp04">요청사항</label>
							</th>
							<td>
								<div className="inp-cell request">
									<textarea id="inp04"></textarea>
									<span className="place-holder">요청사항</span>
								</div>
								<p className="chk">기타 업소측에 요청하실 사항을 입력하세요.</p>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div className="terms">
				<div className="title">
					<h2>이용약관</h2>
					<div className="btns">
						<button onClick={openAccordAll}>모두열기</button>
						<button onClick={closeAccordAll}>모두닫기</button>
					</div>
				</div>
				<button className={`check-all ${allChecked ? 'active' : ''}`} onClick={checkAll}>
					전체동의
				</button>
				<ul className="accord-wrap">
					{terms.map((q, i) => (
						<li key={i} className={opened.includes(i) ? 'accord-panel active' : 'accord-panel'}>
							<div className="accord-title">
								<div className="checkbox">
									<input type="checkbox" id={'check0' + i} onChange={() => checkEach(i)} checked={checkedList.includes(i) ? true : false} />
									<label className="h4" htmlFor={'check0' + i}>
										{q.title}
									</label>
								</div>
								<button onClick={() => openAccord(i)}>
									<span className="hide">아코디언 버튼</span>
								</button>
							</div>
							<div className="accord-content">
								<div dangerouslySetInnerHTML={{ __html: q.content }}></div>
							</div>
						</li>
					))}
				</ul>
			</div>
			<div className="pay">
				<p>총 결제금액</p>
				<span>{bookData.price}</span>
				<p>결제수단</p>
				<span>신용카드</span>
				<span>무통장입금</span>
			</div>
		</div>
	);
}
export default Payment;

import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Gnb from './Gnb';

function Header() {
	// 경로이름 가져오기
	let pathName = useLocation().pathname;
	(function pathFormat() {
		pathName = pathName.substring(pathName.lastIndexOf('/') + 1);
	})();

	// 메뉴
	const [menuToggle, setMenuToggle] = useState(false);
	const menuClick = () => {
		setMenuToggle((prev) => !prev);
	};

	// 스크롤 여부
	const [scrolled, setScrolled] = useState(false);
	const [windowW, setWindowW] = useState(window.innerWidth);
	const [contentT, setContentT] = useState();

	const scrollToggle = () => {
		// 너비별 contentT
		setWindowW(window.innerWidth);
		if (windowW <= 768) {
			setContentT(138);
		} else if (windowW > 768 && windowW <= 1280) {
			setContentT(222);
		} else {
			setContentT(258);
		}
		// contentT 보다 작을경우 스크롤 false
		if (window.scrollY <= contentT) {
			setScrolled(false);
			setMenuToggle(false);
		} else {
			setScrolled(true);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', scrollToggle);
		return () => {
			window.removeEventListener('scroll', scrollToggle);
		};
	});

	return (
		<header className={scrolled ? 'active' : ''}>
			<div className="header-inner">
				<Link to="/" className="home">
					<i className="logo"></i>
				</Link>
				<p className="h2">{pathName === '' ? 'pensionbonjour' : pathName}</p>
				<button className={menuToggle ? 'btn-menu active' : 'btn-menu'} onClick={menuClick}>
					<p className="hide">메뉴</p>
					<span></span>
				</button>
			</div>
			<div className={menuToggle ? 'menu active' : 'menu'}>
				<Gnb className="gnb" menuClick={menuClick} />
			</div>
		</header>
	);
}

export default Header;

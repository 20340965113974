import {useState} from "react"
import { HashRouter, Routes, Route } from 'react-router-dom';

import Main from '../views/Main';
import About from '../views/About';
import Rooms from '../views/Rooms';
import Around from '../views/Around';
import Reservation from '../views/Reservation';
import Payment from '../views/Payment';
import News from '../views/News';
import Faq from '../views/Faq';

import Title from './Title';
import Header from './Header';

import Footer from './Footer';

import ScrollTop from './ScrollTop';

const Router = () => {
	// 메뉴 열기 토글
	const [menuOpened, setMenuOpened] = useState(false);
	// 테마 모드 토글
	const [themeMode, setThemeMode] = useState(false);
	return (
		<>
			<HashRouter>
				<ScrollTop />
				<Header />
				<Title />
				<Routes>
					<Route path="/" element={<Main />} />
					<Route path="about" element={<About />} />
					<Route path="rooms" element={<Rooms />} />
					<Route path="around" element={<Around />} />
					<Route path="reservation" element={<Reservation />} />
					<Route path="payment" element={<Payment />} />
					<Route path="news" element={<News />} />
					<Route path="faq" element={<Faq />} />
				</Routes>
				<Footer />
			</HashRouter>
		</>
	);
};

export default Router;

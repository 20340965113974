import Router from './Router';
// import { useEffect, useState, useRef } from 'react';
import { useEffect } from 'react';

// modal import
import ReactModal from 'react-modal';
import ModalsProvider from './modal/ModalsProvider';
ReactModal.setAppElement('#root');

function App() {
	// const [loading, setLoading] = useState(true);
	// const loader = useRef();
	const opening = () => {
		const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
		if (!isTouchDevice) {
			document.body.classList.add('pc');
		}
		// setLoading(false);
		// loader.current.style.animation = 'fade-out 1s ease-in-out 0s 1 forwards';
		// setTimeout(() => {
		// 	loader.current.remove();
		// }, 1000);
	};
	useEffect(() => {
		window.addEventListener('load', opening);
		return () => {
			window.removeEventListener('load', opening);
		};
	}, []);

	return (
		<>
			{/* <div className={`loading ${loading ? '' : 'active'}`} ref={loader}>
				<div className="spinner h4">Loading</div>
			</div> */}
			<ModalsProvider>
				<div id="wrap">
					<Router />
				</div>
			</ModalsProvider>
		</>
	);
}

export default App;

import useModals from '../components/hooks/useModals';
import { modals } from '../components/modal/Modals';

import { newsData } from '../assets/js/Data';

function News() {
	// 모달
	const { openModal } = useModals();
	const handleClick = (e) => {
		openModal(modals.News, {
			dataId: Number(e.currentTarget.dataset.id),
		});
	};

	// 문자열 html으로 변환
	const stringToHTML = function (str) {
		var dom = document.createElement('div');
		dom.innerHTML = str;
		return dom;
	};
	return (
		<div className="content news">
			{newsData.map((news) => (
				<button onClick={handleClick} key={news.id} data-id={news.id}>
					<figure className="news-figure">
						<img src={`${process.env.PUBLIC_URL}/img/news/${news.id}0.jpg`} alt="" />
						<figcaption>{news.caption}</figcaption>
					</figure>
					<h3>{news.title}</h3>
					<p className="date">{news.date}</p>
					<p
						className="text"
						dangerouslySetInnerHTML={{
							__html: stringToHTML(news.html).innerText,
						}}
					></p>
				</button>
			))}
		</div>
	);
}

export default News;

import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';

function ActiveLink({ path, ko }) {
	// 경로이름 가져오기
	let pathName = useLocation().pathname;

	const [pathText, setPathText] = useState(path);
	(function pathFormat() {
		if (pathText.includes('/')) {
			setPathText((prev) => prev.substring(prev.lastIndexOf('/') + 1));
		}
	})();
	return (
		<Link to={`/${path}`} className={pathName.includes(path) ? 'active' : ''}>
			<span className="en">{pathText}</span>
			<span className="ko">{ko}</span>
		</Link>
	);
}

export default ActiveLink;

import useModals from '../components/hooks/useModals';
import { modals } from '../components/modal/Modals';

import { roomData, facilityData } from '../assets/js/Data';

function Rooms() {
	const { openModal } = useModals();
	const roomOpen = (e) => {
		openModal(modals.Room, {
			dataId: Number(e.currentTarget.dataset.id),
		});
	};
	const facilityOpen = (e) => {
		openModal(modals.Facility, {
			dataId: Number(e.currentTarget.dataset.id),
		});
	};

	return (
		<div className="content rooms">
			{roomData.map((room) => (
				<div className="detail" key={room.id}>
					<h2 className="title">
						{room.en}
						<span className="h3"> {room.ko}</span>
					</h2>
					<button onClick={roomOpen} data-id={room.id}>
						<figure className="roomData-figure">
							<img src={`${process.env.PUBLIC_URL}/img/room/${room.en.charAt(0)}0.jpg`} alt="" />
							<img src={`${process.env.PUBLIC_URL}/img/room/${room.en.charAt(0)}1.jpg`} alt="" />
							<figcaption>{room.ko} 객실 내부의 모습</figcaption>
						</figure>
						<div className="cont">
							<table className="small">
								<colgroup>
									<col width="22%" />
								</colgroup>
								<tbody>
									<tr>
										<th>인원</th>
										<td className="alL">{room.personnel}</td>
									</tr>
									<tr>
										<th>객실구성</th>
										<td className="alL">{room.config}</td>
									</tr>
									<tr>
										<th>크기</th>
										<td className="alL">{room.size}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</button>
				</div>
			))}
			<div className="map">
				<div className="title with-viewmore">
					<h2>객실배치도</h2>
					<a className="h4" href={`${process.env.PUBLIC_URL}/img/room/room_overview.jpg`} target="_blank" rel="noreferrer" title="새창">
						사진보기
					</a>
				</div>
				<h3 className="sub-title">배치도 내 객실 이름을 클릭하시면 정보를 확인하실 수 있습니다.</h3>
				<ul className="info-list">
					{roomData.map((room) => (
						<li key={room.en} className="info">
							<p className="info-title">
								<strong className={room.en}>{room.en}</strong> <span>{room.personnel}</span>
							</p>
							<div className="info-content">
								{room.count.map((data, i) => (
									<button key={data} onClick={roomOpen} data-id={room.id}>
										<i className={data}>{data}</i> <span>{room.en === 'mountain' ? room.en : room.en + (i + 1)}</span>
									</button>
								))}
							</div>
						</li>
					))}
					<li className="info ex">
						<p className="info-title">그 외 시설</p>
						<div className="info-content">
							{facilityData.map((facility) => (
								<button key={facility.count} onClick={facilityOpen} data-id={facility.id}>
									<i className="ex">{facility.count}</i> <span>{facility.name}</span>
								</button>
							))}
						</div>
					</li>
				</ul>
				<figure className="map-figure">
					<i className="img"></i>
					<figcaption>봉쥬르펜션의 객실배치도</figcaption>
					<div className="btn-modal">
						{roomData.map((room) =>
							room.count.map((data) => (
								<button key={data} className={data} onClick={roomOpen} data-id={room.id}>
									{data}
								</button>
							)),
						)}
						{facilityData.map((facility) => (
							<button key={facility.count} className={'ex ' + facility.count} onClick={facilityOpen} data-id={facility.id}>
								{facility.count}
							</button>
						))}
					</div>
				</figure>
			</div>
		</div>
	);
}

export default Rooms;
